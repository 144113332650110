import { createAction } from "redux-actions";

export const uiActions = {
  SNACKBAR_SUCCESS: createAction("SNACKBAR_SUCCESS", (message) => {
    return {
      message,
    };
  }),
  SNACKBAR_ERROR: createAction("SNACKBAR_ERROR", (message) => {
    return {
      message,
    };
  }),
  SNACKBAR_LOADING: createAction("SNACKBAR_LOADING"),
  SNACKBAR_CLEAR: createAction("SNACKBAR_CLEAR"),
};

export const loadingSnackbarAction = () => (dispatch) => {
  dispatch(uiActions.SNACKBAR_LOADING());
}

export const errorSnackbarAction = (message) => (dispatch) => {
  dispatch(uiActions.SNACKBAR_ERROR(message));
};

export const clearSnackbarAction = () => (dispatch) => {
  dispatch(uiActions.SNACKBAR_CLEAR());
};

export const successSnackbarAction = (message) => (dispatch) => {
  dispatch(uiActions.SNACKBAR_SUCCESS(message));
};
