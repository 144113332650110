import React, { Component } from "react";
import { connect } from "react-redux";
import RetreatList from "./retreatList";
import RetreatCreate from "./retreatCreate";
import RetreatDetail from "./retreatDetail";
import RetreatEdit from "./retreatEdit";

import { bindActionCreators } from "redux";
import { getRetreatsAction } from "../../actions/retreatActions";
import { Route, Switch } from "react-router-dom";

class Retreats extends Component {
  componentWillMount() {
    const { getRetreats } = this.props;
    getRetreats();
  }
  componentWillUnmount() {
    console.log("unmounting");
  }

  render() {
    return (
      <Switch>
        <Route exact path="/retreats" component={RetreatList} />
        <Route exact path="/retreats/create" component={RetreatCreate} />
        <Route exact path="/retreats/:id" component={RetreatDetail} />
        <Route exact path="/retreats/:id/edit" component={RetreatEdit} />
      </Switch>
    );
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getRetreats: getRetreatsAction,
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(Retreats);
