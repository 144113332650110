import { createAction } from "redux-actions";
import history from "../utils/history";
import API from "../utils/api";
import { uiActions } from "./uiActions";

export const courseActions = {
  GET_COURSES: createAction("GET_COURSES"),
  GET_COURSES_FINISHED: createAction("GET_COURSES_FINISHED", (courses) => {
    return [...courses];
  }),
  GET_COURSES_ERROR: createAction("GET_COURSES_ERROR", (error) => {
    return {
      error,
    };
  }),
  CREATE_COURSE: createAction("CREATE_COURSE"),
  CREATE_COURSE_FINISHED: createAction("CREATE_COURSE_FINISHED", (course) => {
    return {
      course,
    };
  }),
  CREATE_COURSE_ERROR: createAction("CREATE_COURSE_ERROR", (error) => {
    return {
      error,
    };
  }),
  DELETE_COURSE: createAction("DELETE_COURSE"),
  DELETE_COURSE_FINISHED: createAction("DELETE_COURSE_FINISHED", (course) => {
    return {
      course,
    };
  }),
  DELETE_COURSE_ERROR: createAction("DELETE_COURSE_ERROR", (error) => {
    return {
      error,
    };
  }),
  EDIT_COURSE: createAction("EDIT_COURSE"),
  EDIT_COURSE_FINISHED: createAction("EDIT_COURSE_FINISHED", (course) => {
    return {
      course,
    };
  }),
  EDIT_COURSE_ERROR: createAction("EDIT_COURSE_ERROR", (error) => {
    return {
      error,
    };
  }),
};

export const getCoursesAction = () => async (
  dispatch,
  getState,
  getFirebase
) => {
  dispatch(courseActions.GET_COURSES());
  const firebase = getFirebase();
  const token = await firebase.auth().currentUser.getIdToken();
  API.get("/courses", { headers: { Authorization: `Bearer ${token}` } })
    .then((res) => {
      dispatch(courseActions.GET_COURSES_FINISHED(res.data));
    })
    .catch((error) => {
      dispatch(courseActions.GET_COURSES_ERROR(error));
    });
};

export const editCourseAction = (
  courseId,
  course,
  blob,
  backgroundBlob
) => async (dispatch, getState, getFirebase) => {
  dispatch(courseActions.EDIT_COURSE());
  dispatch(uiActions.SNACKBAR_LOADING());
  const firebase = getFirebase();
  const token = await firebase.auth().currentUser.getIdToken();
  if (backgroundBlob && blob) {
    const data = new FormData();
    data.append("file", backgroundBlob);
    API.post("/courses/upload", data, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => {
        course.imageUrl = res.data;
        const data2 = new FormData();
        data2.append("file", blob);
        API.post("/courses/upload", data2, {
          headers: { Authorization: `Bearer ${token}` },
        })
          .then((res) => {
            course.backgroundImageUrl = res.data;
            API.put(`/courses/${courseId}`, course, {
              headers: { Authorization: `Bearer ${token}` },
            })
              .then((res) => {
                dispatch(courseActions.EDIT_COURSE_FINISHED(res.data));
                dispatch(uiActions.SNACKBAR_SUCCESS("Taller actualizado"));
                history.goBack();
              })
              .catch((error) => {
                dispatch(courseActions.EDIT_COURSE_ERROR(error));
                dispatch(uiActions.SNACKBAR_ERROR("Error al actualizar"));
              });
          })
          .catch((error) => {
            dispatch(courseActions.CREATE_COURSE_ERROR(error));
            dispatch(uiActions.SNACKBAR_ERROR("Error al actualizar"));
          });
      })
      .catch((error) => {
        dispatch(courseActions.CREATE_COURSE_ERROR(error));
        dispatch(uiActions.SNACKBAR_ERROR("Error al actualizar"));
      });
  } else if (blob) {
    const data = new FormData();
    data.append("file", blob);
    API.post("/courses/upload", data, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => {
        console.log(res.data);
        course.backgroundImageUrl = res.data;
        API.put(`/courses/${courseId}`, course, {
          headers: { Authorization: `Bearer ${token}` },
        })
          .then((res) => {
            dispatch(courseActions.EDIT_COURSE_FINISHED(res.data));
            dispatch(uiActions.SNACKBAR_SUCCESS("Taller actualizado"));
            history.goBack();
          })
          .catch((error) => {
            dispatch(courseActions.EDIT_COURSE_ERROR(error));
            dispatch(uiActions.SNACKBAR_ERROR("Error al actualizar"));
          });
      })
      .catch((error) => {
        dispatch(courseActions.EDIT_COURSE_ERROR(error));
        dispatch(uiActions.SNACKBAR_ERROR("Error al actualizar"));
      });
  } else if (backgroundBlob) {
    const data = new FormData();
    data.append("file", backgroundBlob);
    API.post("/courses/upload", data, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => {
        console.log(res.data);
        course.imageUrl = res.data;
        API.put(`/courses/${courseId}`, course, {
          headers: { Authorization: `Bearer ${token}` },
        })
          .then((res) => {
            dispatch(courseActions.EDIT_COURSE_FINISHED(res.data));
            dispatch(uiActions.SNACKBAR_SUCCESS("Taller actualizado"));
            history.goBack();
          })
          .catch((error) => {
            dispatch(courseActions.EDIT_COURSE_ERROR(error));
            dispatch(uiActions.SNACKBAR_ERROR("Error al actualizar"));
          });
      })
      .catch((error) => {
        dispatch(courseActions.EDIT_COURSE_ERROR(error));
        dispatch(uiActions.SNACKBAR_ERROR("Error al actualizar"));
      });
  } else {
    API.put(`/courses/${courseId}`, course, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => {
        dispatch(courseActions.EDIT_COURSE_FINISHED(res.data));
        dispatch(uiActions.SNACKBAR_SUCCESS("Taller actualizado"));
        history.goBack();
      })
      .catch((error) => {
        dispatch(courseActions.EDIT_COURSE_ERROR(error));
        dispatch(uiActions.SNACKBAR_ERROR("Error al actualizar"));
      });
  }
};

export const createCourseAction = (course, blob, backgroundBlob) => async (
  dispatch,
  getState,
  getFirebase
) => {
  dispatch(courseActions.CREATE_COURSE());
  dispatch(uiActions.SNACKBAR_LOADING());
  const firebase = getFirebase();
  const token = await firebase.auth().currentUser.getIdToken();
  const data = new FormData();
  data.append("file", backgroundBlob);
  API.post("/courses/upload", data, {
    headers: { Authorization: `Bearer ${token}` },
  })
    .then((res) => {
      course.imageUrl = res.data;
      const data2 = new FormData();
      data2.append("file", blob);
      API.post("/courses/upload", data2, {
        headers: { Authorization: `Bearer ${token}` },
      })
        .then((res) => {
          course.backgroundImageUrl = res.data;
          API.post("/courses", course, {
            headers: { Authorization: `Bearer ${token}` },
          })
            .then((res) => {
              dispatch(courseActions.CREATE_COURSE_FINISHED(res.data));
              dispatch(uiActions.SNACKBAR_SUCCESS("Taller creado"));
              history.goBack();
            })
            .catch((error) => {
              dispatch(courseActions.CREATE_COURSE_ERROR(error));
              dispatch(uiActions.SNACKBAR_ERROR("No se pudo crear el taller"));
            });
        })
        .catch((error) => {
          dispatch(courseActions.CREATE_COURSE_ERROR(error));
          dispatch(uiActions.SNACKBAR_ERROR("No se pudo crear el taller"));
        });
    })
    .catch((error) => {
      dispatch(courseActions.CREATE_COURSE_ERROR(error));
      dispatch(uiActions.SNACKBAR_ERROR("No se pudo crear el taller"));
    });
};

export const deleteCourseAction = (course) => async (
  dispatch,
  getState,
  getFirebase
) => {
  dispatch(courseActions.DELETE_COURSE());
  dispatch(uiActions.SNACKBAR_LOADING());
  const firebase = getFirebase();
  const token = await firebase.auth().currentUser.getIdToken();
  API.delete(`/courses/${course._id}`, {
    headers: { Authorization: `Bearer ${token}` },
  })
    .then((res) => {
      dispatch(courseActions.DELETE_COURSE_FINISHED(res.data));
      dispatch(uiActions.SNACKBAR_SUCCESS("Taller borrado"));
    })
    .catch((error) => {
      console.log(error);
      dispatch(courseActions.DELETE_COURSE_ERROR(error));
      dispatch(uiActions.SNACKBAR_ERROR("No se pudo borrar el taller"));
    });
};
