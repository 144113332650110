export const dispatch = (action, data, dispatch) => {
    dispatch({
        type: "UPDATED_COURSE",
        payload: data
    })
}

export default {
    action: "UPDATED_COURSE",
    dispatch
}