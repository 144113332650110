import "date-fns";
import React, { useRef, useState } from "react";
import { Container, Row, Col, Button, Form } from "react-bootstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { editSubclassAction } from "../../../actions/subclassActions";
import { getClasses } from "../../../reducers/classReducer";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  KeyboardTimePicker,
} from "@material-ui/pickers";

const SubClassEdit = (props) => {
  const { history, editSubclass, subclass } = props;
  const [imageName, setImageName] = useState("");
  const [audioName, setAudioName] = useState("");
  const [title, setTitle] = useState(subclass ? subclass.title : "");
  const [description, setDescription] = useState(
    subclass ? subclass.description : ""
  );
  const [fuente, setFuente] = useState(subclass?.source ?? "");
  const [link, setLink] = useState(subclass?.link ?? "");
  const [posicion, setPosicion] = useState(subclass?.position ?? "");
  const [selectedDate, setSelectedDate] = useState(
    subclass?.date ?? new Date()
  );
  const [meetingLink, setMeetingLink] = useState(subclass?.meetingLink ?? "");

  const imageRef = useRef(null);
  const audioRef = useRef(null);

  const handleSubmit = (e) => {
    e.preventDefault();
    const body = {
      _id: props.match.params.subclassId,
      title: title,
      description: description,
      source: fuente,
      link: link,
      meetingLink: meetingLink,
      date: selectedDate,
      position: posicion,
    };
    const blob = imageRef.current.files[0];
    const audio = audioRef.current.files[0];
    editSubclass(props.match.params.id, body, blob, audio);
  };

  const handleImageSelected = (e) => {
    if (e.target.files && e.target.files[0]) {
      e.persist();
      setImageName(e.target.files[0].name);
    }
  };

  const handleAudioSelected = (e) => {
    if (e.target.files && e.target.files[0]) {
      e.persist();
      setAudioName(e.target.files[0].name);
    }
  };

  const handleDateChange = (date) => setSelectedDate(date);
  const handleTimeChange = (date) => setSelectedDate(date);

  if (!subclass) return <div></div>;
  if (title === "") setTitle(subclass.title);
  if (description === "") setDescription(subclass.description);
  return (
    <Container className="mt-5">
      <Row>
        <Col>
          <h1>
            Editar {subclass.title}
            <Button
              onClick={() => history.goBack()}
              className="borderedButtonRed ml-5 mb-1"
            >
              Volver
            </Button>
          </h1>
        </Col>
      </Row>
      <br></br>
      <br></br>
      <Row>
        <Col>
          <Form onSubmit={handleSubmit}>
            <Form.Group>
              <Form.Control
                id="title"
                onChange={(e) => setTitle(e.target.value)}
                type="text"
                placeholder="Titulo"
                defaultValue={title}
              />
            </Form.Group>
            <Form.Group>
              <Form.Control
                id="description"
                onChange={(e) => setDescription(e.target.value)}
                as="textarea"
                rows="2"
                placeholder="Descripcion"
                defaultValue={description}
              />
            </Form.Group>
            <Form.Group>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  format="dd/MM/yyyy"
                  margin="normal"
                  id="subclassDate"
                  label="Fecha de subclase"
                  value={selectedDate}
                  onChange={handleDateChange}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
                <KeyboardTimePicker
                  margin="normal"
                  id="time-picker"
                  label="Horario"
                  value={selectedDate}
                  onChange={handleTimeChange}
                  KeyboardButtonProps={{
                    "aria-label": "change time",
                  }}
                />
              </MuiPickersUtilsProvider>
            </Form.Group>
            <Form.Group>
              <Form.Label>{imageName}</Form.Label>
              <Form.File
                ref={imageRef}
                id="image"
                label="Imagen de la clase"
                data-browse="Buscar"
                onChange={(e) => handleImageSelected(e)}
                custom
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>{audioName}</Form.Label>
              <Form.File
                ref={audioRef}
                id="audio"
                label="Audio de la clase"
                data-browse="Buscar Audio"
                onChange={(e) => handleAudioSelected(e)}
                custom
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Link de reunion</Form.Label>
              <Form.Control
                onChange={(e) => setMeetingLink(e.target.value)}
                id="meetingLink"
                defaultValue={meetingLink}
                placeHolder="Link de la reunion"
              />
            </Form.Group>
            <fieldset>
              <Form.Group>
                <Form.Label as="legend" column sm={2}>
                  Fuente
                </Form.Label>
                <Col sm={10}>
                  <Form.Check
                    inline
                    onChange={(e) => setFuente(e.target.value)}
                    checked={fuente === "vimeo" ? true : false}
                    type="radio"
                    label="Vimeo"
                    value="vimeo"
                    name="fuente"
                  />
                  <Form.Check
                    inline
                    onChange={(e) => setFuente(e.target.value)}
                    checked={fuente === "instagram" ? true : false}
                    type="radio"
                    label="Instagram"
                    value="instagram"
                    name="fuente"
                  />
                  <Form.Check
                    inline
                    onChange={(e) => setFuente(e.target.value)}
                    checked={fuente === "audio" ? true : false}
                    type="radio"
                    label="Audio"
                    value="audio"
                    name="fuente"
                  />
                </Col>
              </Form.Group>
            </fieldset>
            <Form.Group>
              <Form.Label>Link</Form.Label>
              <Form.Control
                id="link"
                onChange={(e) => setLink(e.target.value)}
                type="text"
                placeholder="Link"
                defaultValue={link}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Posicion</Form.Label>
              <Form.Control
                id="posicion"
                onChange={(e) => setPosicion(e.target.value)}
                type="text"
                placeholder="Posicion"
                defaultValue={posicion}
              />
            </Form.Group>
            <Button type="submit" className="mainButton">
              Confirmar
            </Button>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = (state, ownParams) => {
  if (getClasses(state).length !== 0) {
    return {
      subclass: getClasses(state)
        .find((element) => element._id === ownParams.match.params.id)
        .subclasses.find(
          (element) => element._id === ownParams.match.params.subclassId
        ),
    };
  }
  return {
    subclass: undefined,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      editSubclass: (classId, subclass, blob, audio) =>
        editSubclassAction(classId, subclass, blob, audio),
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(SubClassEdit);
