import React, { useEffect } from "react";
import { Switch, Route } from "react-router-dom";
import UserList from "./userList";
import { useSelector, useDispatch } from "react-redux";
import { getAllUsersAction } from "../../actions/userActions";

const Users = () => {
  const auth = useSelector((state) => state.firebaseState.auth);
  const dispatch = useDispatch();
  useEffect(() => {
    if (auth.uid) dispatch(getAllUsersAction(auth.uid));
  }, [auth, dispatch]);
  return (
    <Switch>
      <Route path="/users" component={UserList} />
    </Switch>
  );
};

export default Users;
