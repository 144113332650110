import { handleActions } from "redux-actions";

export const retreatsReducer = handleActions(
  {
    UPDATED_RETREAT: (state, action) => {
      return {
        ...state,
        retreats: [
          ...state.retreats.filter(
            (element) => element._id !== action.payload._id
          ),
          action.payload,
        ],
      };
    },
    GET_RETREATS: (state, action) => {
      return {
        ...state,
        fetching: true,
      };
    },
    GET_RETREATS_FINISHED: (state, action) => {
      return {
        ...state,
        fetching: false,
        retreats: action.payload,
      };
    },
    GET_RETREATS_ERROR: (state, action) => {
      return {
        ...state,
        fetching: false,
        error: action.error,
      };
    },
    CREATE_RETREAT: (state, action) => {
      return {
        ...state,
        fetching: true,
      };
    },
    CREATE_RETREAT_FINISHED: (state, action) => {
      return {
        ...state,
        fetching: false,
        retreats: [...state.retreats, action.payload.retreat],
      };
    },
    CREATE_RETREAT_ERROR: (state, action) => {
      return {
        ...state,
        fetching: false,
        error: action.error,
      };
    },
    DELETE_RETREAT: (state, action) => {
      return {
        ...state,
        fetching: true,
      };
    },
    DELETE_RETREAT_FINISHED: (state, action) => {
      return {
        ...state,
        fetching: false,
        retreats: [
          ...state.retreats.filter(
            (retreat) => retreat._id !== action.payload.retreat._id
          ),
        ],
      };
    },
    DELETE_RETREAT_ERROR: (state, action) => {
      return {
        ...state,
        fetching: false,
        error: action.error,
      };
    },
    EDIT_RETREAT: (state, action) => {
      return {
        ...state,
        fetching: true,
      };
    },
    EDIT_RETREAT_FINISHED: (state, action) => {
      return {
        ...state,
        retreats: [
          ...state.retreats.filter(
            (element) => element._id !== action.payload.retreat._id
          ),
          action.payload.retreat,
        ],
      };
    },
  },
  {
    fetching: false,
    retreats: [],
    error: null,
  }
);

export const getRetreats = (state) => state.retreatState.retreats;
export const getRetreatsFetching = (state) => state.retreatState.fetching;
export const getRetreatsError = (state) => state.retreatState.error;
