import React, { useRef, useState } from "react";
import { Container, Row, Col, Button, Form } from "react-bootstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { createSubclassAction } from "../../../actions/subclassActions";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  KeyboardTimePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

const SubClassCreate = (props) => {
  const { history, createSubclass } = props;
  const [imageName, setImageName] = useState("");
  const [audioName, setAudioName] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [fuente, setFuente] = useState("");
  const [link, setLink] = useState("");
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [meetingLink, setMeetingLink] = useState("");
  const [posicion, setPosicion] = useState("");
  const imageRef = useRef(null);
  const audioRef = useRef(null);

  const handleDateChange = (date) => setSelectedDate(date);
  const handleTimeChange = (date) => setSelectedDate(date);

  const handleSubmit = (e) => {
    e.preventDefault();
    const body = {
      title: title,
      description: description,
      class: props.match.params.id,
      source: fuente,
      link: link,
      meetingLink: meetingLink,
      date: selectedDate,
      posicion: posicion,
    };
    const blob = imageRef.current.files[0];
    const audio = audioRef.current.files[0];
    createSubclass(body, blob, audio);
  };

  const handleImageSelected = (e) => {
    if (e.target.files && e.target.files[0]) {
      e.persist();
      setImageName(e.target.files[0].name);
    }
  };

  const handleAudioSelected = (e) => {
    if (e.target.files && e.target.files[0]) {
      e.persist();
      setAudioName(e.target.files[0].name);
    }
  };

  return (
    <Container className="mt-5">
      <Row>
        <Col>
          <h1>
            Nueva Subclase
            <Button
              onClick={() => history.goBack()}
              className="borderedButtonRed ml-5 mb-1"
            >
              Volver
            </Button>
          </h1>
        </Col>
      </Row>
      <br></br>
      <br></br>
      <Row>
        <Col>
          <Form onSubmit={handleSubmit}>
            <Form.Group>
              <Form.Control
                id="title"
                onChange={(e) => setTitle(e.target.value)}
                type="text"
                placeholder="Titulo"
              />
            </Form.Group>
            <Form.Group>
              <Form.Control
                id="description"
                onChange={(e) => setDescription(e.target.value)}
                as="textarea"
                rows="2"
                placeholder="Descripcion"
              />
            </Form.Group>
            <Form.Group>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  format="dd/MM/yyyy"
                  margin="normal"
                  id="subclassDate"
                  label="Fecha de subclase"
                  value={selectedDate}
                  onChange={handleDateChange}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
                <KeyboardTimePicker
                  margin="normal"
                  id="time-picker"
                  label="Horario"
                  value={selectedDate}
                  onChange={handleTimeChange}
                  KeyboardButtonProps={{
                    "aria-label": "change time",
                  }}
                />
              </MuiPickersUtilsProvider>
            </Form.Group>
            <Form.Group>
              <Form.Label>{imageName}</Form.Label>
              <Form.File
                ref={imageRef}
                id="image"
                label="Imagen de la clase"
                data-browse="Buscar"
                onChange={(e) => handleImageSelected(e)}
                custom
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>{audioName}</Form.Label>
              <Form.File
                ref={audioRef}
                id="audio"
                label="Audio de la clase"
                data-browse="Buscar Audio"
                onChange={(e) => handleAudioSelected(e)}
                custom
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Link de reunion</Form.Label>
              <Form.Control
                onChange={(e) => setMeetingLink(e.target.value)}
                id="meetingLink"
                defaultValue={meetingLink}
                placeHolder="Link de la reunion"
              />
            </Form.Group>
            <fieldset>
              <Form.Group>
                <Form.Label as="legend" column sm={2}>
                  Fuente
                </Form.Label>
                <Col sm={10}>
                  <Form.Check
                    inline
                    onChange={(e) => setFuente(e.target.value)}
                    type="radio"
                    label="Vimeo"
                    value="vimeo"
                    name="fuente"
                  />
                  <Form.Check
                    inline
                    onChange={(e) => setFuente(e.target.value)}
                    type="radio"
                    label="Instagram"
                    value="instagram"
                    name="fuente"
                  />
                  <Form.Check
                    inline
                    onChange={(e) => setFuente(e.target.value)}
                    type="radio"
                    label="Audio"
                    value="audio"
                    name="fuente"
                  />
                </Col>
              </Form.Group>
            </fieldset>
            <Form.Group>
              <Form.Label>Link</Form.Label>
              <Form.Control
                id="link"
                onChange={(e) => setLink(e.target.value)}
                type="text"
                placeholder="Link"
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Posicion</Form.Label>
              <Form.Control
                id="posicion"
                onChange={(e) => setPosicion(e.target.value)}
                type="text"
                placeholder="Posicion"
              />
            </Form.Group>
            <Button type="submit" className="mainButton">
              Confirmar
            </Button>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      createSubclass: (subclass, blob, audio) =>
        createSubclassAction(subclass, blob, audio),
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(SubClassCreate);
