import { handleActions } from "redux-actions";

export const authReducer = handleActions(
  {
    GET_LOGIN: (state, action) => {
      return {
        ...state,
        authError: null,
        fetching: true,
      };
    },
    GET_LOGIN_FINISHED: (state, action) => {
      return {
        ...state,
        fetching: false,
        profile: action.payload.user,
      };
    },
    GET_LOGIN_FAILED: (state, action) => {
      return {
        ...state,
        fetching: false,
        authError: action.payload,
      };
    },
    GET_REGISTER: (state, action) => {
      return {
        ...state,
        fetching: true,
        authError: null,
      };
    },
    GET_REGISTER_FINISHED: (state, action) => {
      return {
        ...state,
        profile: action.payload.user,
        fetching: false,
      };
    },
    GET_REGISTER_FAILED: (state, action) => {
      return {
        ...state,
        fetching: false,
        authError: action.payload,
      };
    },
    GET_LOGOUT: (state, action) => {
      return {
        ...state,
        fetching: true,
        authError: null,
      };
    },
    GET_LOGOUT_FINISHED: (state, action) => {
      return {
        ...state,
        profile: null,
        fetching: false,
        authError: null,
      };
    },
    GET_LOGOUT_FAILED: (state, action) => {
      return {
        ...state,
        fetching: false,
        authError: action.payload,
      };
    },
    GET_USER: (state, action) => {
      return {
        ...state,
        fetching: true,
        authError: null,
      };
    },
    GET_USER_FINISHED: (state, action) => {
      return {
        ...state,
        fetching: false,
        profile: action.payload.user,
      };
    },
    GET_USER_FAILED: (state, action) => {
      return {
        ...state,
        fetching: false,
        authError: action.payload,
      };
    },
    UPDATE_USER: (state, action) => {
      return {
        ...state,
        fetching: true,
        authError: null,
      };
    },
    UPDATE_USER_FINISHED: (state, action) => {
      return {
        ...state,
        fetching: false,
        profile: action.payload.user,
        updateStatus: true,
      };
    },
    UPDATE_USER_FAILED: (state, action) => {
      return {
        ...state,
        fetching: false,
      };
    },
  },
  {
    profile: null,
    authError: null,
    fetching: false,
  }
);
