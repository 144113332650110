import React, { useEffect } from "react";
import { connect } from "react-redux";
import { getClasses } from "../../reducers/classReducer";
import { bindActionCreators } from "redux";
import { getClassesAction } from "../../actions/classActions";
import { Container, Row, Image, Button, Col } from "react-bootstrap";
import { Link } from "react-router-dom";

const ClassDetail = (props) => {
  const { lalaClass, fetchClasses, history } = props;
  useEffect(() => {
    if (!lalaClass) {
      fetchClasses();
    }
  }, [fetchClasses, lalaClass]);
  if (!lalaClass) return <div></div>;
  return (
    <Container className="mt-5">
      <Row>
        <Col>
          <h1 className="display-5">
            {lalaClass.title}
            <Button
              onClick={() => history.goBack()}
              className="borderedButtonRed ml-5 mb-1"
            >
              Volver
            </Button>
            <Link to={`/classes/${lalaClass._id}/edit`} className="ml-3">
              <Button className="borderedButton mb-1">Editar</Button>
            </Link>
            <Link to={`/classes/${lalaClass._id}/subclasses`} className="ml-3">
              <Button className="borderedButton mb-1">Subclases</Button>
            </Link>
          </h1>
          <br></br>
          <h3>Descripcion</h3>
          <p>{lalaClass.description}</p>
          <h3>Descripcion corta</h3>
          <p>{lalaClass.shortDescription}</p>
          {lalaClass.link && (
            <Row>
              <Col>
                <h3>Video</h3>
                <iframe
                  src={lalaClass.link}
                  width="640"
                  height="360"
                  title={lalaClass.link}
                  frameBorder="0"
                  allow="autoplay; fullscreen"
                  allowFullscreen
                ></iframe>
              </Col>
            </Row>
          )}
        </Col>
        <Col xs={3}>
          <h3>Imagen de fondo</h3>
          <Image src={lalaClass.backgroundImageUrl} fluid />
          <br></br>
          <h3>Imagen del curso</h3>
          <Image src={lalaClass.imageUrl} fluid />
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = (state, ownParams) => {
  return {
    lalaClass: getClasses(state).find(
      (element) => element._id === ownParams.match.params.id
    ),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchClasses: () => getClassesAction(),
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ClassDetail);
