import { createAction } from "redux-actions";
import history from "../utils/history";
import API from "../utils/api";
import { uiActions } from "./uiActions";

export const subclassActions = {
  GET_SUBCLASSES: createAction("GET_SUBCLASSES"),
  GET_SUBCLASSES_FINISHED: createAction(
    "GET_SUBCLASSES_FINISHED",
    (subclasses) => {
      return {
        subclasses,
      };
    }
  ),
  GET_SUBCLASSES_FAILED: createAction("GET_SUBCLASSES_FAILED", (error) => {
    return {
      error,
    };
  }),
  CREATE_SUBCLASS: createAction("CREATE_SUBCLASS"),
  CREATE_SUBCLASS_FINISHED: createAction(
    "CREATE_SUBCLASS_FINISHED",
    (lalaClass) => {
      return {
        lalaClass,
      };
    }
  ),
  CREATE_SUBCLASS_FAILED: createAction("CREATE_SUBCLASS_FAILED", (error) => {
    return {
      error,
    };
  }),
  DELETE_SUBCLASS: createAction("DELETE_SUBCLASS"),
  DELETE_SUBCLASS_FINISHED: createAction(
    "DELETE_SUBCLASS_FINISHED",
    (lalaClass) => {
      return {
        lalaClass,
      };
    }
  ),
  DELETE_SUBCLASS_FAILED: createAction("DELETE_SUBCLASS_FAILED", (error) => {
    return {
      error,
    };
  }),
  EDIT_SUBCLASS: createAction("EDIT_SUBCLASS"),
  EDIT_SUBCLASS_FINISHED: createAction(
    "EDIT_SUBCLASS_FINISHED",
    (lalaClass) => {
      return {
        lalaClass,
      };
    }
  ),
  EDIT_SUBCLASS_FAILED: createAction("EDIT_SUBCLASS_FAILED", (error) => {
    return {
      error,
    };
  }),
};

export const createSubclassAction = (subclass, blob, audio) => async (
  dispatch,
  getState,
  getFirebase
) => {
  dispatch(subclassActions.CREATE_SUBCLASS());
  dispatch(uiActions.SNACKBAR_LOADING());
  const firebase = getFirebase();
  const token = await firebase.auth().currentUser.getIdToken();
  if (audio && blob) {
    const dataAudio = new FormData();
    dataAudio.append("file", audio);
    API.post("/classes/upload", dataAudio, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => {
        subclass.audioLink = res.data;
        const data = new FormData();
        data.append("file", blob);
        API.post("/classes/upload", data, {
          headers: { Authorization: `Bearer ${token}` },
        })
          .then((res) => {
            subclass.imageUrl = res.data;
            API.post(`classes/${subclass.class}/subclasses`, subclass, {
              headers: { Authorization: `Bearer ${token}` },
            })
              .then((res) => {
                dispatch(subclassActions.CREATE_SUBCLASS_FINISHED(res.data));
                dispatch(uiActions.SNACKBAR_SUCCESS("Tema creado"));
                history.goBack();
              })
              .catch((error) => {
                dispatch(subclassActions.CREATE_SUBCLASS_FAILED(error));
                dispatch(uiActions.SNACKBAR_ERROR("No se pudo crear el tema"));
              });
          })
          .catch((error) => {
            dispatch(subclassActions.CREATE_SUBCLASS_FAILED(error));
            dispatch(uiActions.SNACKBAR_ERROR("No se pudo crear el tema"));
          });
      })
      .catch((error) => {
        dispatch(subclassActions.CREATE_SUBCLASS_FAILED(error));
        dispatch(uiActions.SNACKBAR_ERROR("No se pudo crear el tema"));
      });
  } else {
    const data = new FormData();
    data.append("file", blob);
    API.post("/classes/upload", data, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => {
        subclass.imageUrl = res.data;
        API.post(`classes/${subclass.class}/subclasses`, subclass, {
          headers: { Authorization: `Bearer ${token}` },
        })
          .then((res) => {
            dispatch(subclassActions.CREATE_SUBCLASS_FINISHED(res.data));
            dispatch(uiActions.SNACKBAR_SUCCESS("Tema creado"));
            history.goBack();
          })
          .catch((error) => {
            dispatch(subclassActions.CREATE_SUBCLASS_FAILED(error));
            dispatch(uiActions.SNACKBAR_ERROR("No se pudo crear el tema"));
          });
      })
      .catch((error) => {
        dispatch(subclassActions.CREATE_SUBCLASS_FAILED(error));
        dispatch(uiActions.SNACKBAR_ERROR("No se pudo crear el tema"));
      });
  }
};

export const deleteSubclassAction = (classId, subclassId) => async (
  dispatch,
  getState,
  getFirebase
) => {
  dispatch(subclassActions.DELETE_SUBCLASS());
  dispatch(uiActions.SNACKBAR_LOADING());
  const firebase = getFirebase();
  const token = await firebase.auth().currentUser.getIdToken();
  API.delete(`/classes/${classId}/subclasses/${subclassId}`, {
    headers: { Authorization: `Bearer ${token}` },
  })
    .then((res) => {
      dispatch(subclassActions.DELETE_SUBCLASS_FINISHED(res.data));
      dispatch(uiActions.SNACKBAR_SUCCESS("Tema borrado"));
    })
    .catch((error) => {
      dispatch(subclassActions.DELETE_SUBCLASS_FAILED(error));
      dispatch(uiActions.SNACKBAR_ERROR("No se pudo borrar"));
    });
};

export const editSubclassAction = (classId, subclass, blob, audio) => async (
  dispatch,
  getState,
  getFirebase
) => {
  dispatch(subclassActions.EDIT_SUBCLASS());
  dispatch(uiActions.SNACKBAR_LOADING());
  const firebase = getFirebase();
  const token = await firebase.auth().currentUser.getIdToken();
  if (audio && blob) {
    const dataAudio = new FormData();
    dataAudio.append("file", audio);
    API.post("/classes/upload", dataAudio, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => {
        subclass.audioLink = res.data;
        const data = new FormData();
        data.append("file", blob);
        API.post("/classes/upload", data, {
          headers: { Authorization: `Bearer ${token}` },
        })
          .then((res) => {
            subclass.imageUrl = res.data;
            API.put(
              `/classes/${classId}/subclasses/${subclass._id}`,
              subclass,
              {
                headers: { Authorization: `Bearer ${token}` },
              }
            )
              .then((res) => {
                dispatch(subclassActions.EDIT_SUBCLASS_FINISHED(res.data));
                dispatch(uiActions.SNACKBAR_SUCCESS("Tema actualizado"));
                history.goBack();
              })
              .catch((error) => {
                dispatch(subclassActions.EDIT_SUBCLASS_FAILED(error));
                dispatch(uiActions.SNACKBAR_ERROR("No se pudo actualizar"));
              });
          })
          .catch((error) => {
            dispatch(subclassActions.EDIT_SUBCLASS_FAILED(error));
            dispatch(uiActions.SNACKBAR_ERROR("No se pudo actualizar"));
          });
      })
      .catch((error) => {
        dispatch(subclassActions.EDIT_SUBCLASS_FAILED(error));
        dispatch(uiActions.SNACKBAR_ERROR("No se pudo actualizar"));
        console.log(error);
      });
  } else if (blob) {
    const data = new FormData();
    data.append("file", blob);
    API.post("/classes/upload", data, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => {
        subclass.imageUrl = res.data;
        API.put(`/classes/${classId}/subclasses/${subclass._id}`, subclass, {
          headers: { Authorization: `Bearer ${token}` },
        })
          .then((res) => {
            dispatch(subclassActions.EDIT_SUBCLASS_FINISHED(res.data));
            dispatch(uiActions.SNACKBAR_SUCCESS("Tema actualizado"));
            history.goBack();
          })
          .catch((error) => {
            dispatch(subclassActions.EDIT_SUBCLASS_FAILED(error));
            dispatch(uiActions.SNACKBAR_ERROR("No se pudo actualizar"));
          });
      })
      .catch((error) => {
        dispatch(subclassActions.EDIT_SUBCLASS_FAILED(error));
        dispatch(uiActions.SNACKBAR_ERROR("No se pudo actualizar"));
      });
  } else if (audio) {
    const dataAudio = new FormData();
    dataAudio.append("file", audio);
    API.post("/classes/upload", dataAudio, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => {
        subclass.audioLink = res.data;
        API.put(`/classes/${classId}/subclasses/${subclass._id}`, subclass, {
          headers: { Authorization: `Bearer ${token}` },
        })
          .then((res) => {
            dispatch(subclassActions.EDIT_SUBCLASS_FINISHED(res.data));
            dispatch(uiActions.SNACKBAR_SUCCESS("Tema actualizado"));
            history.goBack();
          })
          .catch((error) => {
            dispatch(subclassActions.EDIT_SUBCLASS_FAILED(error));
            dispatch(uiActions.SNACKBAR_ERROR("No se pudo actualizar"));
          });
      })
      .catch((error) => {
        dispatch(subclassActions.EDIT_SUBCLASS_FAILED(error));
        dispatch(uiActions.SNACKBAR_ERROR("No se pudo actualizar"));
        console.log(error);
      });
  } else {
    API.put(`/classes/${classId}/subclasses/${subclass._id}`, subclass, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => {
        dispatch(subclassActions.EDIT_SUBCLASS_FINISHED(res.data));
        dispatch(uiActions.SNACKBAR_SUCCESS("Tema actualizado"));
        history.goBack();
      })
      .catch((error) => {
        dispatch(subclassActions.EDIT_SUBCLASS_FAILED(error));
        dispatch(uiActions.SNACKBAR_ERROR("No se pudo actualizar"));
      });
  }
};
