import { Container, Row, Col, Button, Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import { getCourses } from "../../reducers/courseReducer";

import React, { Component } from "react";
import { connect } from "react-redux";

class CourseDetail extends Component {
  render() {
    const { match, courses, history } = this.props;
    const course = courses.find((element) => element._id === match.params.id);
    if (course) {
      return (
        <Container className="mt-5">
          <Row>
            <Col>
              <h1 className="display-5">
                {course.title}
                <Button
                  onClick={() => history.goBack()}
                  className="borderedButtonRed ml-5 mb-1"
                >
                  Volver
                </Button>
                <Link to={`/courses/${course._id}/edit`} className="ml-3">
                  <Button className="borderedButton mb-1">Editar</Button>
                </Link>
              </h1>
              <br></br>
              <h3>Descripcion</h3>
              <p>{course.description}</p>
              <h3>Descripcion corta</h3>
              <p>{course.shortDescription}</p>
            </Col>
            <Col xs={3}>
              <h3>Imagen de fondo</h3>
              <Image src={course.backgroundImageUrl} fluid />
              <br></br>
              <h3>Imagen del curso</h3>
              <Image src={course.imageUrl} fluid />
            </Col>
          </Row>
        </Container>
      );
    }
    return <div></div>;
  }
}

const mapStateToProps = (state) => {
  return {
    courses: getCourses(state),
  };
};

export default connect(mapStateToProps)(CourseDetail);
