import { handleActions } from "redux-actions";

export const usersReducer = handleActions(
  {
    GET_ALL_USERS: (state, action) => {
      return {
        ...state,
        fetching: true,
        error: null,
      };
    },
    GET_ALL_USERS_FINISHED: (state, action) => {
      console.log(action);
      return {
        ...state,
        fetching: false,
        users: action.payload,
      };
    },
    GET_ALL_USERS_FAILED: (state, action) => {
      return {
        ...state,
        fetching: false,
        error: action.error,
      };
    },
  },
  {
    fetching: false,
    users: [],
    error: null,
  }
);
