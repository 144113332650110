import React, { useState, useRef } from "react";
import { Container, Row, Col, Button, Form } from "react-bootstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { createClassAction } from "../../actions/classActions";
const ClassCreate = (props) => {
  const { createClass } = props;
  const [imageName, setImageName] = useState("");
  const [backgroundImageName, setBackgroundImageName] = useState("");
  const [title, setTitle] = useState("");
  const [shortDescription, setShortDescription] = useState("");
  const [description, setDescription] = useState("");
  const [link, setLink] = useState("");
  const [position, setPosition] = useState();
  const [showDate, setShowDate] = useState(true);
  const [isTrainee, setIsTrainee] = useState(false);
  const imageRef = useRef(null);
  const backgroundImageRef = useRef(null);

  const handleSubmit = (e) => {
    e.preventDefault();
    const body = {
      title: title,
      description: description,
      shortDescription: shortDescription,
      link: link,
      position: position,
      showDate,
      isTrainee,
    };
    const blob = imageRef.current.files[0];
    const backgroundBlob = backgroundImageRef.current.files[0];
    createClass(body, blob, backgroundBlob);
  };

  const handleImageSelected = (e) => {
    if (e.target.files && e.target.files[0]) {
      e.persist();
      setImageName(e.target.files[0].name);
    }
  };

  const handleBackgroundImageSelected = (e) => {
    if (e.target.files && e.target.files[0]) {
      e.persist();
      setBackgroundImageName(e.target.files[0].name);
    }
  };
  return (
    <Container className="mt-5">
      <Row>
        <Col>
          <h1>
            Nueva Clase
            <Button
              onClick={() => props.history.goBack()}
              className="borderedButtonRed ml-5 mb-1"
            >
              Volver
            </Button>
          </h1>
        </Col>
      </Row>
      <br></br>
      <br></br>
      <Row>
        <Col>
          <Form onSubmit={handleSubmit}>
            <Form.Group>
              <Form.Control
                required
                id="title"
                onChange={(e) => setTitle(e.target.value)}
                type="text"
                placeholder="Nombre de la clase"
              />
            </Form.Group>
            <Form.Group>
              <Form.Control
                required
                id="shortDescription"
                onChange={(e) => setShortDescription(e.target.value)}
                as="textarea"
                maxLength="255"
                rows="2"
                placeholder="Descripcion corta de la clase"
              />
            </Form.Group>
            <Form.Group>
              <Form.Control
                required
                id="description"
                onChange={(e) => setDescription(e.target.value)}
                as="textarea"
                rows="4"
                placeholder="Descripcion de la clase"
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Posicion</Form.Label>
              <Form.Control
                required
                id="position"
                onChange={(e) => setPosition(e.target.value)}
                type="number"
                placeholder="Posicion de la clase"
              />
            </Form.Group>
            <Form.Group controlId="formBasicCheckbox">
              <Form.Check
                type="checkbox"
                label="Mostrar Fecha"
                checked={showDate}
                onChange={(e) => {
                  setShowDate(e.target.checked);
                }}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Link</Form.Label>
              <Form.Control
                defaultValue={link}
                placeholder="Link del video"
                onChange={(e) => setLink(e.target.value)}
                id="link"
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>{imageName}</Form.Label>
              <Form.File
                required
                ref={imageRef}
                id="image"
                label="Imagen de la clase"
                data-browse="Buscar"
                onChange={(e) => handleImageSelected(e)}
                custom
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>{backgroundImageName}</Form.Label>
              <Form.File
                required
                ref={backgroundImageRef}
                id="backgroundImage"
                label="Imagen de fondo de la clase"
                data-browse="Buscar"
                onChange={(e) => handleBackgroundImageSelected(e)}
                custom
              />
            </Form.Group>
            <Form.Group controlId="formBasicCheckbox">
              <Form.Check
                type="checkbox"
                label="Es voluntariado"
                checked={isTrainee}
                onChange={(e) => {
                  setIsTrainee(e.target.checked);
                }}
              />
            </Form.Group>
            <Button type="submit" className="mainButton">
              Confirmar
            </Button>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      createClass: (lalaClass, blob, backgroundBlob) =>
        createClassAction(lalaClass, blob, backgroundBlob),
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(ClassCreate);
