import React from "react";
import { Link } from "react-router-dom";
import { Col, Button } from "react-bootstrap";

const Sidebar = () => {
  return (
    <Col xs={12} className="pt-5 px-0 sidebar mainColorBackground h-100">
      <Link to="/">
        <Button className="sidebarButton mt-5" block>
          Inicio
        </Button>
      </Link>
      <Link to="/courses">
        <Button className="sidebarButton" block>
          Cursos
        </Button>
      </Link>
      <Link to="/classes">
        <Button className="sidebarButton" block>
          Clases
        </Button>
      </Link>
      <Link to="/retreats">
        <Button className="sidebarButton" block>
          Retiros
        </Button>
      </Link>
      <Link to="/users">
        <Button className="sidebarButton" block>
          Usuarios
        </Button>
      </Link>
    </Col>
  );
};

export default Sidebar;
