import React, { Component } from "react";
import { socketActions } from "../actions/socketActions";
import { useSelector, connect } from "react-redux";
import { isLoaded } from "react-redux-firebase";
import LoadingPage from "../components/loadingpage";
import Dashboard from "../components/dashboard";
import { Route, Switch } from "react-router-dom";
import Login from "../components/login";
import SuccessSnackbar from "../components/snackbar/successSnackbar";
import ErrorSnackBar from "../components/snackbar/errorSnackbar";
import LoadingSnackBar from "../components/snackbar/loadingSnackbar";

function AuthIsLoaded({ children }) {
  const auth = useSelector((state) => state.firebaseState.auth);
  if (!isLoaded(auth)) return <LoadingPage />;
  return children;
}
class App extends Component {
  componentDidMount() {
    if (process.env.NODE_ENV === "production") {
      this.props.dispatch(
        socketActions.LALA_CONNECT("api.lauramadeira.com", null)
      );
    } else {
      this.props.dispatch(socketActions.LALA_CONNECT("localhost", 5000));
    }
  }
  render() {
    return (
      <AuthIsLoaded>
        <SuccessSnackbar />
        <ErrorSnackBar />
        <LoadingSnackBar />
        <Switch>
          <Route path="/login" component={Login} />
          <Route path="/" component={Dashboard} />
        </Switch>
      </AuthIsLoaded>
    );
  }
}

const mapStateToProps = (state) => {
  return state;
};

export default connect(mapStateToProps)(App);
