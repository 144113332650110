import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import rootReducer from "../reducers";
import socketMiddelware from "../middleware/socket/socket";
import { reactReduxFirebase, getFirebase } from "react-redux-firebase";
import fbConfig from "../config/fbConfig";

const configureStore = (preloadedState) =>
  createStore(
    rootReducer,
    preloadedState,
    compose(
      applyMiddleware(thunk.withExtraArgument(getFirebase), socketMiddelware),
      reactReduxFirebase(fbConfig)
    )
  );

export default configureStore;
