import { createAction } from "redux-actions";
import history from "../utils/history";
import API from "../utils/api";
import { uiActions } from "./uiActions";

export const retreatActions = {
  GET_RETREATS: createAction("GET_RETREATS"),
  GET_RETREATS_FINISHED: createAction("GET_RETREATS_FINISHED", (retreats) => {
    return [...retreats];
  }),
  GET_RETREATS_ERROR: createAction("GET_RETREATS_ERROR", (error) => {
    return {
      error,
    };
  }),
  CREATE_RETREAT: createAction("CREATE_RETREAT"),
  CREATE_RETREAT_FINISHED: createAction(
    "CREATE_RETREAT_FINISHED",
    (retreat) => {
      return {
        retreat,
      };
    }
  ),
  CREATE_RETREAT_ERROR: createAction("CREATE_RETREAT_ERROR", (error) => {
    return {
      error,
    };
  }),
  DELETE_RETREAT: createAction("DELETE_RETREAT"),
  DELETE_RETREAT_FINISHED: createAction(
    "DELETE_RETREAT_FINISHED",
    (retreat) => {
      return {
        retreat,
      };
    }
  ),
  DELETE_RETREAT_ERROR: createAction("DELETE_RETREAT_ERROR", (error) => {
    return {
      error,
    };
  }),
  EDIT_RETREAT: createAction("EDIT_RETREAT"),
  EDIT_RETREAT_FINISHED: createAction("EDIT_RETREAT_FINISHED", (retreat) => {
    return {
      retreat,
    };
  }),
  EDIT_RETREAT_ERROR: createAction("EDIT_RETREAT_ERROR", (error) => {
    return {
      error,
    };
  }),
};

export const getRetreatsAction =
  () => async (dispatch, getState, getFirebase) => {
    dispatch(retreatActions.GET_RETREATS());
    const firebase = getFirebase();
    const token = await firebase.auth().currentUser.getIdToken();
    API.get("/retreats", { headers: { Authorization: `Bearer ${token}` } })
      .then((res) => {
        dispatch(retreatActions.GET_RETREATS_FINISHED(res.data));
      })
      .catch((error) => {
        dispatch(retreatActions.GET_RETREATS_ERROR(error));
      });
  };

export const editRetreatAction =
  (retreatId, retreat, blob) => async (dispatch, getState, getFirebase) => {
    dispatch(retreatActions.EDIT_RETREAT());
    dispatch(uiActions.SNACKBAR_LOADING());
    const firebase = getFirebase();
    const token = await firebase.auth().currentUser.getIdToken();
    if (blob) {
      const data = new FormData();
      data.append("file", blob);
      API.post("/retreats/upload", data, {
        headers: { Authorization: `Bearer ${token}` },
      })
        .then((res) => {
          console.log(res.data);
          retreat.imageUrl = res.data;
          API.put(`/retreats/${retreatId}`, retreat, {
            headers: { Authorization: `Bearer ${token}` },
          })
            .then((res) => {
              dispatch(retreatActions.EDIT_RETREAT_FINISHED(res.data));
              dispatch(uiActions.SNACKBAR_SUCCESS("Retiro actualizado"));
              history.goBack();
            })
            .catch((error) => {
              dispatch(retreatActions.EDIT_RETREAT_ERROR(error));
              dispatch(uiActions.SNACKBAR_ERROR("Error al actualizar"));
            });
        })
        .catch((error) => {
          dispatch(retreatActions.EDIT_RETREAT_ERROR(error));
          dispatch(uiActions.SNACKBAR_ERROR("Error al actualizar"));
        });
    } else {
      API.put(`/retreats/${retreatId}`, retreat, {
        headers: { Authorization: `Bearer ${token}` },
      })
        .then((res) => {
          dispatch(retreatActions.EDIT_RETREAT_FINISHED(res.data));
          dispatch(uiActions.SNACKBAR_SUCCESS("Retiro actualizado"));
          history.goBack();
        })
        .catch((error) => {
          dispatch(retreatActions.EDIT_RETREAT_ERROR(error));
          dispatch(uiActions.SNACKBAR_ERROR("Error al actualizar"));
        });
    }
  };

export const createRetreatAction =
  (retreat, backgroundBlob) => async (dispatch, getState, getFirebase) => {
    dispatch(retreatActions.CREATE_RETREAT());
    dispatch(uiActions.SNACKBAR_LOADING());
    const firebase = getFirebase();
    const token = await firebase.auth().currentUser.getIdToken();
    const data = new FormData();
    data.append("file", backgroundBlob);
    API.post("/retreats/upload", data, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => {
        retreat.imageUrl = res.data;
        API.post("/retreats", retreat, {
          headers: { Authorization: `Bearer ${token}` },
        })
          .then((res) => {
            dispatch(retreatActions.CREATE_RETREAT_FINISHED(res.data));
            dispatch(uiActions.SNACKBAR_SUCCESS("Retiro creado"));
            history.goBack();
          })
          .catch((error) => {
            dispatch(retreatActions.CREATE_RETREAT_ERROR(error));
            dispatch(uiActions.SNACKBAR_ERROR("No se pudo crear el retiro"));
          });
      })
      .catch((error) => {
        dispatch(retreatActions.CREATE_RETREAT_ERROR(error));
        dispatch(uiActions.SNACKBAR_ERROR("No se pudo crear el retiro"));
      });
  };

export const deleteRetreatAction =
  (retreat) => async (dispatch, getState, getFirebase) => {
    dispatch(retreatActions.DELETE_RETREAT());
    dispatch(uiActions.SNACKBAR_LOADING());
    const firebase = getFirebase();
    const token = await firebase.auth().currentUser.getIdToken();
    API.delete(`/retreats/${retreat._id}`, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => {
        dispatch(retreatActions.DELETE_RETREAT_FINISHED(res.data));
        dispatch(uiActions.SNACKBAR_SUCCESS("Retiro borrado"));
      })
      .catch((error) => {
        console.log(error);
        dispatch(retreatActions.DELETE_RETREAT_ERROR(error));
        dispatch(uiActions.SNACKBAR_ERROR("No se pudo borrar el retiro"));
      });
  };
