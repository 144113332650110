import React, { Component } from "react";
import { Row, Col, Container, Button, Form } from "react-bootstrap";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import DateFnsUtils from "@date-io/date-fns";
import {
  KeyboardDatePicker,
  KeyboardTimePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { createRetreatAction } from "../../actions/retreatActions";
import { addDays } from "date-fns";
class RetreatCreate extends Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.image = React.createRef();
  }
  state = {
    retreat: {
      title: "",
      description: "",
      date: new Date(),
      endDate: new addDays(new Date(), 1),
    },
    imageName: "",
    showModal: false,
  };

  handleDateChange = (date) => {
    this.setState((prevState) => ({
      ...prevState,
      retreat: {
        ...prevState.retreat,
        date: date,
      },
    }));
  };

  handleEndDateChange = (date) => {
    this.setState((prevState) => ({
      ...prevState,
      retreat: {
        ...prevState.retreat,
        endDate: date,
      },
    }));
  };

  handleTimeChange = (date) => {
    this.setState((prevState) => ({
      ...prevState,
      retreat: {
        ...prevState.retreat,
        date: date,
      },
    }));
  };

  handleChange = (e) => {
    e.persist();
    this.setState((prevState) => ({
      ...prevState,
      retreat: {
        ...prevState.retreat,
        [e.target.id]: e.target.value,
      },
    }));
  };

  handleClassChange = (e) => {
    e.persist();
    this.setState((prevState) => ({
      ...prevState,
    }));
  };

  handleShowModal = () => {
    this.setState((prevState) => ({
      ...prevState,
      showModal: true,
    }));
  };

  handleHideModal = () => {
    this.setState((prevState) => ({
      ...prevState,
      showModal: false,
    }));
  };

  handleImageSelected = (e) => {
    if (e.target.files && e.target.files[0]) {
      e.persist();
      this.setState((prevState) => ({
        ...prevState,
        [e.target.id]: e.target.files[0].name,
      }));
    }
  };
  handleSubmit = (e) => {
    e.preventDefault();
    const blob = this.image.current.files[0];
    this.props.createRetreat(this.state.retreat, blob);
  };
  render() {
    return (
      <Container className="mt-5">
        <Row>
          <Col>
            <h1 className="display-5">
              Nuevo Retiro
              <Button
                onClick={() => this.props.history.goBack()}
                className="borderedButtonRed ml-5 mb-1"
              >
                Volver
              </Button>
            </h1>
          </Col>
        </Row>
        <br></br>
        <br></br>
        <Row>
          <Col>
            <Form onSubmit={this.handleSubmit}>
              <Form.Group>
                <Form.Control
                  id="title"
                  onChange={this.handleChange}
                  type="text"
                  placeholder="Nombre del retiro"
                ></Form.Control>
              </Form.Group>
              <Form.Group>
                <Form.Control
                  onChange={this.handleChange}
                  id="description"
                  as="textarea"
                  rows="4"
                  placeholder="Descripcion del retiro"
                ></Form.Control>
              </Form.Group>
              <Form.Group>
                <Form.Control
                  id="link"
                  onChange={this.handleChange}
                  type="text"
                  placeholder="Video del retiro"
                ></Form.Control>
              </Form.Group>
              <Form.Group>
                <Form.Label>{this.state.imageName}</Form.Label>
                <Form.File
                  ref={this.image}
                  id="imageName"
                  label="Imagen del fondo"
                  data-browse="Buscar"
                  onChange={this.handleImageSelected}
                  custom
                ></Form.File>
              </Form.Group>
              <Form.Group>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    format="dd/MM/yyyy"
                    margin="normal"
                    id="retreatDate"
                    label="Inicio del retiro"
                    value={this.state.retreat.date}
                    onChange={this.handleDateChange}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                </MuiPickersUtilsProvider>
              </Form.Group>
              <Form.Group>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    format="dd/MM/yyyy"
                    margin="normal"
                    id="retreatEndDate"
                    label="Cierre del retiro"
                    value={this.state.retreat.endDate}
                    onChange={this.handleEndDateChange}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                </MuiPickersUtilsProvider>
              </Form.Group>
              <Button type="submit" className="mainButton">
                Confirmar
              </Button>
            </Form>
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    success: state.retreatState.createdSuccess,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      createRetreat: (course, blob) => createRetreatAction(course, blob),
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(RetreatCreate);
