import React, { Component } from "react";
import { connect } from "react-redux";
import { getCourses } from "../../reducers/courseReducer";
import { Container, Col, Row, Form, InputGroup, Button } from "react-bootstrap";
import { bindActionCreators } from "redux";
import { editCourseAction } from "../../actions/courseActions";

class CourseEdit extends Component {
  constructor(props) {
    super(props);
    this.image = React.createRef();
    this.backgroundImage = React.createRef();
  }

  state = {
    course: this.props.courses.find(
      (element) => element._id === this.props.match.params.id
    ),
    imageName: "",
    backgroundImageName: "",
  };

  handleChange = (e) => {
    e.persist();
    this.setState((prevState) => ({
      ...prevState,
      course: {
        ...prevState.course,
        [e.target.id]: !e.target.value.length ? undefined : e.target.value,
      },
    }));
  };

  handleImageChange = (e) => {
    e.persist();
    this.setState((prevState) => ({
      ...prevState,
      [e.target.id]: e.target.files[0].name,
    }));
  };

  handleOnSubmit = (e) => {
    e.preventDefault();
    const blob = this.image.current.files[0];
    const backgroundBlob = this.backgroundImage.current.files[0];
    this.props.editCourse(
      this.props.match.params.id,
      this.state.course,
      blob,
      backgroundBlob
    );
  };

  render() {
    const { courses, match } = this.props;
    console.log(courses);
    const course = courses.find((element) => element._id === match.params.id);
    if (course) {
      return (
        <Container className="mt-5">
          <Row>
            <Col>
              <h1 className="display-5">
                {course.title}
                <Button
                  onClick={() => this.props.history.goBack()}
                  className="borderedButtonRed ml-5 mb-1"
                >
                  Volver
                </Button>
              </h1>
            </Col>
          </Row>
          <br></br>
          <br></br>
          <Row>
            <Col>
              <Form onSubmit={this.handleOnSubmit}>
                <Form.Group>
                  <Form.Control
                    id="title"
                    type="text"
                    placeholder="Nombre del curso"
                    onChange={this.handleChange}
                    defaultValue={course.title}
                  ></Form.Control>
                </Form.Group>
                <Form.Group>
                  <Form.Control
                    id="shortDescription"
                    as="textarea"
                    maxLength="255"
                    rows="2"
                    placeholder="Descripcion corta del curso"
                    onChange={this.handleChange}
                    defaultValue={course.shortDescription}
                  ></Form.Control>
                </Form.Group>
                <Form.Group>
                  <Form.Control
                    id="description"
                    as="textarea"
                    rows="4"
                    placeholder="Descripcion del curso"
                    onChange={this.handleChange}
                    defaultValue={course.description}
                  ></Form.Control>
                </Form.Group>
                <Form.Group>
                  <Form.Label>{this.state.backgroundImageName}</Form.Label>
                  <Form.File
                    ref={this.backgroundImage}
                    id="backgroundImageName"
                    label="Imagen del curso"
                    data-browse="Buscar"
                    onChange={this.handleImageChange}
                    custom
                  ></Form.File>
                </Form.Group>
                <Form.Group>
                  <Form.Label>{this.state.imageName}</Form.Label>
                  <Form.File
                    ref={this.image}
                    id="imageName"
                    label="Imagen del fondo"
                    data-browse="Buscar"
                    onChange={this.handleImageChange}
                    custom
                  ></Form.File>
                </Form.Group>
                <Row>
                  <Col xs={3}>
                    <InputGroup className="mb-3">
                      <InputGroup.Prepend>
                        <InputGroup.Text>$</InputGroup.Text>
                      </InputGroup.Prepend>
                      <Form.Control
                        placeholder="Precio del curso"
                        type="number"
                        onChange={this.handleChange}
                        id="price"
                        defaultValue={course.price}
                      />
                    </InputGroup>
                  </Col>
                </Row>

                <Button type="submit" className="mainButton">
                  Editar
                </Button>
              </Form>
            </Col>
          </Row>
        </Container>
      );
    }
    return <div></div>;
  }
}

const mapStateToProps = (state) => {
  return {
    courses: getCourses(state),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      editCourse: (courseId, course, blob, backgroundBlob) =>
        editCourseAction(courseId, course, blob, backgroundBlob),
    },
    dispatch
  );
export default connect(mapStateToProps, mapDispatchToProps)(CourseEdit);
