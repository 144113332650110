import React from "react";
import { Row, Col, ListGroup, Button } from "react-bootstrap";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { deleteSubclassAction } from "../../../actions/subclassActions";
import { Link } from "react-router-dom";

const SubclassList = (props) => {
  const { lalaClass, deleteSubclass } = props;
  return (
    <Row>
      <Col>
        <ListGroup variant="flush">
          {lalaClass.subclasses &&
            lalaClass.subclasses.map((element) => (
              <ListGroup.Item key={element._id}>
                <Row className="align-items-center">
                  <Col md={2}>
                    <div
                      style={{
                        height: "100px",
                        width: "100px",
                        backgroundImage: `url(${element.imageUrl})`,
                        backgroundSize: "cover",
                        borderRadius: "5px",
                      }}
                      className="shadow"
                    ></div>
                  </Col>
                  <Col>{element.title}</Col>
                  <Col
                    md={4}
                    className="text-right justify-content-center align-items-center"
                  >
                    <Button
                      onClick={() => deleteSubclass(lalaClass._id, element._id)}
                      className="m-2 borderedButtonRed"
                    >
                      Borrar
                    </Button>
                    <Link
                      to={`/classes/${lalaClass._id}/subclasses/${element._id}/edit`}
                    >
                      <Button className="m-2 borderedButton">Editar</Button>
                    </Link>
                  </Col>
                </Row>
              </ListGroup.Item>
            ))}
        </ListGroup>
      </Col>
    </Row>
  );
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      deleteSubclass: (lalaClassId, subclassId) =>
        deleteSubclassAction(lalaClassId, subclassId),
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(SubclassList);
