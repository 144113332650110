import React, { useState, useRef, useEffect } from "react";
import { Container, Row, Col, Button, Form } from "react-bootstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { editClassAction, getClassesAction } from "../../actions/classActions";
import { getClasses } from "../../reducers/classReducer";
const ClassEdit = (props) => {
  const { editClass, lalaClass, loadClasses } = props;
  const [imageName, setImageName] = useState("");
  const [backgroundImageName, setBackgroundImageName] = useState("");
  const [title, setTitle] = useState(lalaClass ? lalaClass.title : "");
  const [shortDescription, setShortDescription] = useState(
    lalaClass ? lalaClass.shortDescription : ""
  );
  const [description, setDescription] = useState(
    lalaClass ? lalaClass.description : ""
  );
  const [showDate, setShowDate] = useState(
    lalaClass ? lalaClass.showDate : true
  );
  const [link, setLink] = useState(lalaClass ? lalaClass.link : "");
  const [position, setPosition] = useState(lalaClass ? lalaClass.position : "");
  const [isTrainee, setIsTrainee] = useState(
    lalaClass ? lalaClass.isTrainee : false
  );
  useEffect(() => {
    if (!lalaClass) {
      loadClasses();
    }
  }, [lalaClass, loadClasses]);

  const imageRef = useRef(null);
  const backgroundImageRef = useRef(null);
  const handleSubmit = (e) => {
    e.preventDefault();
    const body = {
      title: title,
      description: description,
      shortDescription: shortDescription,
      link: link,
      position: position,
      showDate: showDate,
      isTrainee,
    };

    const blob = imageRef.current.files[0];
    const backgroundBlob = backgroundImageRef.current.files[0];
    editClass(lalaClass._id, body, blob, backgroundBlob);
  };

  const handleImageSelected = (e) => {
    if (e.target.files && e.target.files[0]) {
      e.persist();
      setImageName(e.target.files[0].name);
    }
  };

  const handleBackgroundImageSelected = (e) => {
    if (e.target.files && e.target.files[0]) {
      e.persist();
      setBackgroundImageName(e.target.files[0].name);
    }
  };

  if (!lalaClass) return <div></div>;
  return (
    <Container className="mt-5">
      <Row>
        <Col>
          <h1>
            Editar Clase
            <Button
              onClick={() => props.history.goBack()}
              className="borderedButtonRed ml-5 mb-1"
            >
              Volver
            </Button>
          </h1>
        </Col>
      </Row>
      <br></br>
      <br></br>
      <Row>
        <Col>
          <Form onSubmit={handleSubmit}>
            <Form.Group>
              <Form.Control
                required
                id="title"
                onChange={(e) => setTitle(e.target.value)}
                type="text"
                placeholder="Nombre de la clase"
                defaultValue={title}
              />
            </Form.Group>
            <Form.Group>
              <Form.Control
                required
                id="shortDescription"
                onChange={(e) => setShortDescription(e.target.value)}
                as="textarea"
                maxLength="255"
                rows="2"
                placeholder="Descripcion corta de la clase"
                defaultValue={shortDescription}
              />
            </Form.Group>
            <Form.Group>
              <Form.Control
                required
                id="description"
                onChange={(e) => setDescription(e.target.value)}
                as="textarea"
                rows="4"
                placeholder="Descripcion de la clase"
                defaultValue={description}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Posicion</Form.Label>
              <Form.Control
                required
                id="position"
                onChange={(e) => setPosition(e.target.value)}
                type="number"
                placeholder="Posicion de la clase"
                defaultValue={position}
              />
            </Form.Group>
            <Form.Group controlId="formBasicCheckbox">
              <Form.Check
                type="checkbox"
                label="Mostrar Fecha"
                checked={showDate}
                onChange={(e) => {
                  setShowDate(e.target.checked);
                }}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Link</Form.Label>
              <Form.Control
                defaultValue={link}
                placeholder="Link del video"
                onChange={(e) => setLink(e.target.value)}
                id="link"
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>{imageName}</Form.Label>
              <Form.File
                ref={imageRef}
                id="image"
                label="Imagen de la clase"
                data-browse="Buscar"
                onChange={(e) => handleImageSelected(e)}
                custom
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>{backgroundImageName}</Form.Label>
              <Form.File
                ref={backgroundImageRef}
                id="backgroundImage"
                label="Imagen de fondo de la clase"
                data-browse="Buscar"
                onChange={(e) => handleBackgroundImageSelected(e)}
                custom
              />
            </Form.Group>
            <Form.Group controlId="formBasicCheckbox2">
              <Form.Check
                type="checkbox"
                label="Voluntariado"
                checked={isTrainee}
                onChange={(e) => {
                  setIsTrainee(e.target.checked);
                }}
              />
            </Form.Group>
            <Button type="submit" className="mainButton">
              Confirmar
            </Button>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = (state, ownParams) => {
  return {
    lalaClass: getClasses(state).find(
      (element) => element._id === ownParams.match.params.id
    ),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      editClass: (lalaClassId, lalaClass, blob, backgroundBlob) =>
        editClassAction(lalaClassId, lalaClass, blob, backgroundBlob),
      loadClasses: () => getClassesAction(),
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ClassEdit);
