import React, { Component } from "react";
import CourseItem from "./courseItem";
import { Row, Col, Button, Container } from "react-bootstrap";
import { connect } from "react-redux";

import {
  getCourses,
  getCoursesError,
  getCoursesFetching,
} from "../../reducers/courseReducer";
import { Link } from "react-router-dom";

class CourseList extends Component {
  /* shouldComponentRender() {
    const { fetching } = this.props;
    if (fetching === false) return false;
    return true;
  } */

  render() {
    const { courses } = this.props;
    /* if (this.shouldComponentRender()) return <div>CARGANDO...</div>; */
    return (
      <Container className="mt-5">
        <Row>
          <Col>
            <h1 className="display-5">
              Mis Cursos
              <Link to="/courses/create">
                <Button className="borderedButton ml-5 mb-1">
                  Agregar curso
                </Button>
              </Link>
            </h1>
          </Col>
        </Row>
        <Row>
          {courses &&
            courses.map((course) => {
              return (
                <Col key={course._id} xl={3} className="pb-4">
                  <CourseItem course={course} />
                </Col>
              );
            })}
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    courses: getCourses(state),
    error: getCoursesError(state),
    fetching: getCoursesFetching(state),
  };
};

export default connect(mapStateToProps)(CourseList);
