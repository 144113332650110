import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { clearSnackbarAction } from "../../actions/uiActions";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import MuiAlert from "@material-ui/lab/Alert";
import { CircularProgress } from "@material-ui/core";

const SuccessSnackBar = (props) => {
  const { clearSnackbar, loadingSnackbarOpen } = props;

  const handleClose = () => {
    clearSnackbar();
  };
  return (
    <Snackbar
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={loadingSnackbarOpen}
      onClose={handleClose}
      action={
        <React.Fragment>
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleClose}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </React.Fragment>
      }
    >
      <MuiAlert
        elevation={6}
        onClose={handleClose}
        severity="info"
      >
        <CircularProgress />
      </MuiAlert>
    </Snackbar>
  );
};

const mapStateToProps = (state) => {
  return {
    loadingSnackbarOpen: state.uiState.loadingSnackbarOpen,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      clearSnackbar: () => clearSnackbarAction(),
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(SuccessSnackBar);
