import { createAction } from "redux-actions";
import API from "../utils/api";

export const userActions = {
  GET_USER: createAction("GET_USER"),
  GET_USER_FINISHED: createAction("GET_USER_FINISHED", (user) => {
    return {
      user,
    };
  }),
  GET_USER_FAILED: createAction("GET_USER_FAILED", (error) => {
    return {
      error,
    };
  }),
  UPDATE_USER: createAction("UPDATE_USER"),
  UPDATE_USER_FINISHED: createAction("UPDATE_USER_FINISHED", (user) => {
    return {
      user,
    };
  }),
  UPDATE_USER_FAILED: createAction("UPDATE_USER_FAILED", (error) => {
    return {
      error,
    };
  }),
  GET_ALL_USERS: createAction("GET_ALL_USERS"),
  GET_ALL_USERS_FINISHED: createAction("GET_ALL_USERS_FINISHED", (users) => {
    return [...users];
  }),
  GET_ALL_USERS_FAILED: createAction("GET_ALL_USERS_FAILED", (error) => {
    return {
      error,
    };
  }),
};

export const getAllUsersAction = (uid) => async (
  dispatch,
  getState,
  getFirebase
) => {
  dispatch(userActions.GET_ALL_USERS());
  const firebase = getFirebase();
  const token = await firebase.auth().currentUser.getIdToken();
  API.get(`/users`, { headers: { Authorization: `Bearer ${token}` } })
    .then((res) => {
      dispatch(userActions.GET_ALL_USERS_FINISHED(res.data));
    })
    .catch((error) => {
      dispatch(userActions.GET_ALL_USERS_FAILED(error));
    });
};

export const getUserAction = (uid) => async (
  dispatch,
  getState,
  getFirebase
) => {
  dispatch(userActions.GET_USER());
  const firebase = getFirebase();
  const token = await firebase.auth().currentUser.getIdToken();
  API.get(`/users/${uid}`, { headers: { Authorization: `Bearer ${token}` } })
    .then((res) => {
      dispatch(userActions.GET_USER_FINISHED(res.data));
    })
    .catch((error) => {
      dispatch(userActions.GET_USER_FAILED(error));
    });
};

export const updateUserAction = (userData, uid) => async (
  dispatch,
  getState,
  getFirebase
) => {
  dispatch(userActions.UPDATE_USER());
  const firebase = getFirebase();
  const token = await firebase.auth().currentUser.getIdToken();
  API.put(`/users/${uid}`, userData, {
    headers: { Authorization: `Bearer ${token}` },
  })
    .then((res) => {
      dispatch(userActions.UPDATE_USER_FINISHED(res.data));
    })
    .catch((error) => {
      dispatch(userActions.UPDATE_USER_FAILED(error));
    });
};

export const updateUserMembershipAction = (userData, uid) => async (
  dispatch,
  getState,
  getFirebase
) => {
  dispatch(userActions.UPDATE_USER());
  const firebase = getFirebase();
  const token = await firebase.auth().currentUser.getIdToken();
  API.put(`/users/${uid}/changeMembershipStatus`, userData, {
    headers: { Authorization: `Bearer ${token}` },
  })
    .then((res) => {
      dispatch(getAllUsersAction());
    })
    .catch((error) => userActions.UPDATE_USER_FAILED(error));
};
