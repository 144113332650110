import { createAction } from "redux-actions";
import API from "../utils/api";

export const authActions = {
  GET_LOGIN: createAction("GET_LOGIN"),
  GET_LOGIN_FINISHED: createAction("GET_LOGIN_FINISHED", (user) => {
    return {
      user,
    };
  }),
  GET_LOGIN_FAILED: createAction("GET_LOGIN_FAILED", (error) => {
    return {
      error,
    };
  }),
  GET_REGISTER: createAction("GET_REGISTER"),
  GET_REGISTER_FINISHED: createAction("GET_REGISTER_FINISHED", (user) => {
    return user;
  }),
  GET_REGISTER_FAILED: createAction("GET_REGISTER_FAILED", (error) => {
    return {
      error,
    };
  }),
  GET_LOGOUT: createAction("GET_LOGOUT"),
  GET_LOGOUT_FINISHED: createAction("GET_LOGOUT_FINISHED"),
  GET_LOGOUT_FAILED: createAction("GET_LOGOUT_FAILED", (error) => {
    return {
      error,
    };
  }),
};

export const getLogoutAction = () => (dispatch, getState, getFirebase) => {
  dispatch(authActions.GET_LOGOUT());
  const firebase = getFirebase();
  firebase
    .auth()
    .signOut()
    .then(() => {
      dispatch(authActions.GET_LOGOUT_FINISHED());
    })
    .catch((error) => {
      dispatch(authActions.GET_LOGOUT_FAILED(error));
    });
};

export const getRegisterAction = (credentials) => (
  dispatch,
  getState,
  getFirebase
) => {
  dispatch(authActions.GET_REGISTER());
  const firebase = getFirebase();
  firebase
    .auth()
    .createUserWithEmailAndPassword(credentials.email, credentials.password)
    .then((res) => {
      API.post("/users", {
        _id: res.user.uid,
        email: credentials.email,
        newsletter: true,
      })
        .then((res) => {
          dispatch(authActions.GET_REGISTER_FINISHED(res.data));
        })
        .catch((error) => {
          dispatch(authActions.GET_REGISTER_FAILED(error));
        });
    })
    .catch((error) => {
      dispatch(authActions.GET_REGISTER_FAILED(error));
    });
};

export const getLoginAction = (credentials) => (
  dispatch,
  getState,
  getFirebase
) => {
  dispatch(authActions.GET_LOGIN());
  const firebase = getFirebase();
  firebase
    .auth()
    .signInWithEmailAndPassword(credentials.email, credentials.password)
    .then(async (res) => {
      const token = await firebase.auth().currentUser.getIdToken();
      API.get(`/users/${res.user.uid}`, {
        headers: { Authorization: `Bearer ${token}` },
        validateStatus: () => true,
      }).then((res) => {
        console.log("test1");
        if (res.data.admin) {
          dispatch(authActions.GET_LOGIN_FINISHED(res.data));
        } else
          firebase
            .auth()
            .signOut()
            .then(() => {
              console.log("test");
              dispatch(authActions.GET_LOGOUT_FINISHED());
            })
            .catch((error) => dispatch(authActions.GET_LOGOUT_FAILED(error)));
      });
    })
    .catch((error) => {
      dispatch(authActions.GET_LOGIN_FAILED(error));
    });
};
