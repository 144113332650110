import React, { useState } from "react";
import {
  Container,
  Row,
  Col,
  Table,
  FormControl,
  Button,
  Modal,
} from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import LoadingPage from "../loadingpage";
import CheckIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from "@material-ui/icons/CancelRounded";
import { addYears, format, subDays } from "date-fns";
import { es } from "date-fns/locale";
import { updateUserMembershipAction } from "../../actions/userActions";

const UserList = () => {
  const users = useSelector((state) => state.usersState.users);
  const [filteredUsers, setFilteredUsers] = useState(undefined);
  const [filteredAs, setFilteredAs] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [user, setUser] = useState(undefined);
  const [expireDate, setExpireDate] = useState();
  const dispatch = useDispatch();

  const filterBy = (column) => {
    if (filteredAs) {
      setFilteredUsers(
        users.sort((a, b) =>
          a || b
            ? !a[column]
              ? -1
              : !b[column]
              ? 1
              : a[column].localeCompare(b[column])
            : 0
        )
      );
    } else {
      setFilteredUsers(
        users.sort((a, b) =>
          a || b
            ? !b[column]
              ? -1
              : !a[column]
              ? 1
              : b[column].localeCompare(a[column])
            : 0
        )
      );
    }
    setFilteredAs(!filteredAs);
  };
  const filterByStatus = (column) => {
    if (filteredAs) {
      setFilteredUsers(users.sort((a, b) => (a.membershipStatus ? -1 : 1)));
    } else {
      setFilteredUsers(users.sort((a, b) => (a.membershipStatus ? 1 : -1)));
    }
    setFilteredAs(!filteredAs);
  };

  const filterByDate = () => {
    if (filteredAs) {
      setFilteredUsers(
        users.sort((a, b) =>
          a || b
            ? !a.membershipExpiredDate
              ? -1
              : !b.membershipExpiredDate
              ? 1
              : a.membershipExpiredDate.localeCompare(b.membershipExpiredDate)
            : 0
        )
      );
    } else {
      setFilteredUsers(
        users.sort((a, b) =>
          a || b
            ? !b.membershipExpiredDate
              ? -1
              : !a.membershipExpiredDate
              ? 1
              : b.membershipExpiredDate.localeCompare(a.membershipExpiredDate)
            : 0
        )
      );
    }
    setFilteredAs(!filteredAs);
  };

  const handleSearchUser = (e) => {
    if (e.target.value === "") setFilteredUsers(undefined);
    else
      setFilteredUsers(
        users.filter(
          (element) =>
            element.name?.toLowerCase()?.includes(e.target.value) ||
            element.lastName?.toLowerCase()?.includes(e.target.value) ||
            element.email?.toLowerCase()?.includes(e.target.value)
        )
      );
  };

  const handleMembershipStatus = (user) => {
    // if (!user.membershipStatus) {
    //   setUser(user);
    //   setOpenModal(true);
    // } else {
    dispatch(
      updateUserMembershipAction(
        {
          membershipStatus: !user.membershipStatus,
          membershipExpiredDate: user.membershipStatus
            ? subDays(new Date(), 1)
            : addYears(new Date(), 100),
          membershipUpdatedDate: new Date(),
        },
        user._id
      )
    );
    setFilteredUsers(undefined);
    // }
  };

  const handleModalClose = () => {
    setUser(undefined);
    setOpenModal(false);
  };

  const handleMembershipStatusTrue = (user, date) => {
    dispatch(
      updateUserMembershipAction(
        {
          membershipStatus: true,
          membershipExpiredDate: date,
          membershipUpdatedDate: new Date(),
        },
        user._id
      )
    );
    setFilteredUsers(undefined);
  };
  if (!users) return <LoadingPage />;
  return (
    <Container className="mt-5">
      <Row>
        <Col>
          <h1 className="display-5">Usuarios</h1>
        </Col>
        <Col>
          <FormControl
            placeholder="Buscar"
            onChange={handleSearchUser}
          ></FormControl>
        </Col>
      </Row>

      <Row>
        <Col>
          <Table bordered hover className="shadow">
            <thead>
              <tr>
                <th>Total Standard</th>
                <th>Total OSHER</th>
                <th>Total</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  {users.filter((element) => !element.membershipStatus).length}
                </td>
                <td>
                  {users.filter((element) => element.membershipStatus).length}
                </td>
                <td>{users.length}</td>
              </tr>
            </tbody>
          </Table>
        </Col>
      </Row>

      <Row>
        <Col>
          <Table striped bordered hover className="shadow">
            <thead>
              <tr>
                <th onClick={() => filterBy("email")}>Correo</th>
                <th onClick={() => filterBy("name")}>Nombre</th>
                <th onClick={() => filterBy("lastName")}>Apellido</th>
                <th onClick={() => filterByStatus()}>Miembro</th>
                <th onClick={() => filterByDate()}>Expira Membresia</th>
              </tr>
            </thead>
            <tbody>
              {filteredUsers
                ? filteredUsers.map((element) => {
                    return (
                      <tr key={element._id}>
                        <td>{element.email}</td>
                        <td>{element.name}</td>
                        <td>{element.lastName}</td>
                        <td>
                          {element.membershipStatus ? (
                            <>
                              <CheckIcon
                                fontSize="default"
                                style={{ color: "green" }}
                              />
                            </>
                          ) : (
                            <>
                              <CancelIcon
                                fontSize="default"
                                style={{ color: "red" }}
                              />
                            </>
                          )}
                          <Button
                            variant="link"
                            onClick={() => handleMembershipStatus(element)}
                          >
                            Cambiar
                          </Button>
                        </td>
                        <td>
                          {element.membershipExpiredDate &&
                            format(
                              new Date(element.membershipExpiredDate),
                              "EEEE d MMMM",
                              {
                                locale: es,
                              }
                            )}
                        </td>
                      </tr>
                    );
                  })
                : users.map((element) => {
                    return (
                      <tr key={element._id}>
                        <td>{element.email}</td>
                        <td>{element.name}</td>
                        <td>{element.lastName}</td>
                        <td>
                          {element.membershipStatus ? (
                            <>
                              <CheckIcon
                                fontSize="default"
                                style={{ color: "green" }}
                              />
                            </>
                          ) : (
                            <>
                              <CancelIcon
                                fontSize="default"
                                style={{ color: "red" }}
                              />
                            </>
                          )}{" "}
                          <Button
                            variant="link"
                            onClick={() => handleMembershipStatus(element)}
                          >
                            Cambiar
                          </Button>
                        </td>
                        <td>
                          {element.membershipExpiredDate &&
                            format(
                              new Date(element.membershipExpiredDate),
                              "EEEE d MMMM",
                              {
                                locale: es,
                              }
                            )}
                        </td>
                      </tr>
                    );
                  })}
            </tbody>
          </Table>
        </Col>
      </Row>
      <Modal
        show={openModal}
        onHide={handleModalClose}
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {user && user.email}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="show-grid">
          <Container>
            <Row>
              <Col xs={12} md={8}>
                <FormControl
                  type="date"
                  placeholder="Fecha de expiracion"
                  onChange={(e) => setExpireDate(e.target.value)}
                ></FormControl>
              </Col>
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleModalClose}>Cerrar</Button>
          {user && (
            <Button
              onClick={() => handleMembershipStatusTrue(user, expireDate)}
            >
              Actualizar
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default UserList;
