import React, { Component } from "react";
import { Row, Col, Container, Button, Form, InputGroup } from "react-bootstrap";
import { bindActionCreators } from "redux";
import { createCourseAction } from "../../actions/courseActions";
import { connect } from "react-redux";
class CreateCourse extends Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.image = React.createRef();
    this.backgroundImage = React.createRef();
  }
  state = {
    course: {
      title: "",
      description: "",
      shortDescription: "",
      price: "",
    },
    classes: [],
    imageName: "",
    backgroundImageName: "",
    showModal: false,
  };

  handleChange = (e) => {
    e.persist();
    this.setState((prevState) => ({
      ...prevState,
      course: {
        ...prevState.course,
        [e.target.id]: e.target.value,
      },
    }));
  };

  handleClassChange = (e) => {
    e.persist();
    this.setState((prevState) => ({
      ...prevState,
    }));
  };

  handleShowModal = () => {
    console.log(this.state);
    this.setState((prevState) => ({
      ...prevState,
      showModal: true,
    }));
  };

  handleHideModal = () => {
    this.setState((prevState) => ({
      ...prevState,
      showModal: false,
    }));
  };

  handleImageSelected = (e) => {
    if (e.target.files && e.target.files[0]) {
      e.persist();
      this.setState((prevState) => ({
        ...prevState,
        [e.target.id]: e.target.files[0].name,
      }));
    }
  };
  handleSubmit = (e) => {
    e.preventDefault();
    const blob = this.image.current.files[0];
    const blobBackground = this.backgroundImage.current.files[0];
    this.props.createCourse(this.state.course, blob, blobBackground);
  };
  render() {
    return (
      <Container className="mt-5">
        <Row>
          <Col>
            <h1 className="display-5">
              Nuevo Curso
              <Button
                onClick={() => this.props.history.goBack()}
                className="borderedButtonRed ml-5 mb-1"
              >
                Volver
              </Button>
            </h1>
          </Col>
        </Row>
        <br></br>
        <br></br>
        <Row>
          <Col>
            <Form onSubmit={this.handleSubmit}>
              <Form.Group>
                <Form.Control
                  id="title"
                  onChange={this.handleChange}
                  type="text"
                  placeholder="Nombre del curso"
                ></Form.Control>
              </Form.Group>
              <Form.Group>
                <Form.Control
                  onChange={this.handleChange}
                  id="shortDescription"
                  as="textarea"
                  maxLength="255"
                  rows="2"
                  placeholder="Descripcion corta del curso"
                ></Form.Control>
              </Form.Group>
              <Form.Group>
                <Form.Control
                  onChange={this.handleChange}
                  id="description"
                  as="textarea"
                  rows="4"
                  placeholder="Descripcion del curso"
                ></Form.Control>
              </Form.Group>
              <Form.Group>
                <Form.Label>{this.state.imageBackgroundName}</Form.Label>
                <Form.File
                  ref={this.backgroundImage}
                  id="imageBackgroundName"
                  label="Imagen del curso"
                  data-browse="Buscar"
                  onChange={this.handleImageSelected}
                  custom
                ></Form.File>
              </Form.Group>
              <Form.Group>
                <Form.Label>{this.state.imageName}</Form.Label>
                <Form.File
                  ref={this.image}
                  id="imageName"
                  label="Imagen del fondo"
                  data-browse="Buscar"
                  onChange={this.handleImageSelected}
                  custom
                ></Form.File>
              </Form.Group>
              <Row>
                <Col xs={3}>
                  <InputGroup className="mb-3">
                    <InputGroup.Prepend>
                      <InputGroup.Text>$</InputGroup.Text>
                    </InputGroup.Prepend>
                    <Form.Control
                      placeholder="Precio del curso"
                      type="number"
                      onChange={this.handleChange}
                      id="price"
                    />
                  </InputGroup>
                </Col>
              </Row>
              <Form.Group>
                <Button className="mainButton" onClick={this.handleShowModal}>
                  Agregar curso
                </Button>
              </Form.Group>
              <Button type="submit" className="mainButton">
                Confirmar
              </Button>
            </Form>
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    success: state.courseState.createdSuccess,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      createCourse: (course, blob, blobBackground) =>
        createCourseAction(course, blob, blobBackground),
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(CreateCourse);
