import React from "react";
import { connect } from "react-redux";
import {
  getClasses,
  getClassesError,
  getClassesFetching,
} from "../../reducers/classReducer";
import { Container, Button, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import ClassItem from "./classItem";

const ClassList = (props) => {
  const { classes } = props;
  return (
    <Container className="mt-5">
      <Row>
        <Col>
          <h1 className="display-5">
            Mis Clases{" "}
            <Link to="/classes/create">
              <Button className="borderedButton ml-5 mb-1">
                Agregar clase
              </Button>
            </Link>
          </h1>
        </Col>
      </Row>
      <Row>
        {classes &&
          classes.map((element) => {
            return (
              <Col key={element._id} xl={3} className="pb-4">
                <ClassItem lalaClass={element} />
              </Col>
            );
          })}
      </Row>
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    classes: getClasses(state),
    error: getClassesError(state),
    fetching: getClassesFetching(state),
  };
};

export default connect(mapStateToProps)(ClassList);
