import React, { useEffect } from "react";
import MainNavbar from "../navbar/navbar";
import { Route, Switch, Redirect } from "react-router-dom";
import Sidebar from "../sidebar/sidebar";
import { Container, Row, Col } from "react-bootstrap";
import Courses from "../courses";
import Classes from "../classes";
import { useSelector, connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getUserAction } from "../../actions/userActions";
import LoadingPage from "../loadingpage";
import Users from "../users";
import Retreats from "../retreats";

const Dashboard = (props) => {
  const auth = useSelector((state) => state.firebaseState.auth);
  const profile = useSelector((state) => state.authState.profile);
  const { getUser } = props;
  useEffect(() => {
    if (auth.uid)
      if (!profile) {
        getUser(auth.uid);
      }
  }, [auth, profile, getUser]);
  if (!auth.uid) return <Redirect to="/login" />;
  if (!profile) return <LoadingPage />;
  if (profile.email !== "juanwillink@gmail.com")
    return <Redirect to="/login" />;
  return (
    <>
      <MainNavbar profile={profile} />
      <Container fluid style={{ height: "100vh" }}>
        <Row className="h-100">
          <Col xs={2}>
            <Row className="h-100">
              <Sidebar />
            </Row>
          </Col>

          <Col className="pt-5">
            <Switch>
              <Route path="/courses" component={Courses} />
              <Route path="/classes" component={Classes} />
              <Route path="/users" component={Users} />
              <Route path="/retreats" component={Retreats} />
            </Switch>
          </Col>
        </Row>
      </Container>
    </>
  );
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getUser: (uid) => getUserAction(uid),
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(Dashboard);
