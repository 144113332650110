import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import * as serviceWorker from "./serviceWorker";
import configureStore from "./store/configureStore";
import Root from "./containers/Root";
import "./index.scss";
import history from "./utils/history";

const store = configureStore();

ReactDOM.render(
  <Router history={history}>
    <Root store={store} />
  </Router>,
  document.getElementById("root")
);
serviceWorker.unregister();
