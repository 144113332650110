import React from "react";
import { Navbar, Nav } from "react-bootstrap";
import { connect, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { getLogoutAction } from "../../actions/authActions";

const MainNavbar = ({ profile, logout }) => {
  const auth = useSelector((state) => state.firebaseState.auth);
  return (
    <Navbar
      fixed="top"
      expand="lg"
      className="pl-5 pr-5 shadow"
      style={{ backgroundColor: "#75135d" }}
    >
      <Navbar.Brand className="text-white">
        {profile && profile.name + " " + profile.lastName}
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="ml-auto">
          {auth.uid ? (
            <Nav.Link className="text-light" onClick={() => logout()}>
              Cerrar Sesion
            </Nav.Link>
          ) : null}
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      logout: () => getLogoutAction(),
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(MainNavbar);
