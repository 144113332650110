import React, { Component } from "react";
import { Card, Button } from "react-bootstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";
import { deleteRetreatAction } from "../../actions/retreatActions";
import { format } from "date-fns";
import { es } from "date-fns/locale";

class RetreatItem extends Component {
  render() {
    const { retreat, deleteRetreat } = this.props;
    return (
      <Card style={{ border: "none" }} className="shadow h-100">
        <Card.Img
          variant="top"
          src={retreat.imageUrl}
          style={{ maxHeight: "150px", objectFit: "cover" }}
        />
        <Card.Body>
          <Card.Title>{retreat.title}</Card.Title>
          <h5>
            {format(new Date(retreat.date), "EEEE d MMMM HH:mm", {
              locale: es,
            })}
          </h5>
        </Card.Body>
        <Card.Footer className="text-center">
          <Link to={`/retreats/${retreat._id}`}>
            <Button className="mainButton float-left">Ver Retiro</Button>
          </Link>

          <Button
            variant="danger"
            style={{ border: "none" }}
            className="float-right"
            onClick={() => deleteRetreat(retreat)}
          >
            Borrar
          </Button>
        </Card.Footer>
      </Card>
    );
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      deleteRetreat: (retreat) => deleteRetreatAction(retreat),
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(RetreatItem);
