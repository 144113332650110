import React, { Component } from "react";
import { connect } from "react-redux";
import CourseList from "./courseList";
import CreateCourse from "./courseCreate";
import { bindActionCreators } from "redux";
import { getCoursesAction } from "../../actions/courseActions";
import { Route, Switch } from "react-router-dom";
import CourseDetail from "./courseDetail";
import courseEdit from "./courseEdit";

class Courses extends Component {
  componentWillMount() {
    const { getCourses } = this.props;
    getCourses();
  }
  componentWillUnmount() {
    console.log("unmounting");
  }

  render() {
    return (
      <Switch>
        <Route exact path="/courses" component={CourseList} />
        <Route exact path="/courses/create" component={CreateCourse} />
        <Route exact path="/courses/:id" component={CourseDetail} />
        <Route exact path="/courses/:id/edit" component={courseEdit} />
      </Switch>
    );
  }
}

/* const mapStateToProps = (state) => {
  return {
    error: getCoursesError(state),
    courses: getCourses(state),
    fetching: getCoursesFetching(state),
  };
}; */

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getCourses: getCoursesAction,
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(Courses);
