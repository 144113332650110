import { Container, Row, Col, Button, Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import { getRetreats } from "../../reducers/retreatReducer";

import React, { Component } from "react";
import { connect } from "react-redux";
import { format } from "date-fns";
import { es } from "date-fns/locale";

class RetreatDetail extends Component {
  render() {
    const { match, retreats, history } = this.props;
    const retreat = retreats.find((element) => element._id === match.params.id);
    if (retreat) {
      return (
        <Container className="mt-5">
          <Row>
            <Col>
              <h1 className="display-5">
                {retreat.title}
                <Button
                  onClick={() => history.goBack()}
                  className="borderedButtonRed ml-5 mb-1"
                >
                  Volver
                </Button>
                <Link to={`/retreats/${retreat._id}/edit`} className="ml-3">
                  <Button className="borderedButton mb-1">Editar</Button>
                </Link>
              </h1>
              <br></br>
              <h3>Descripcion</h3>
              <p>{retreat.description}</p>
              <h3>Fecha de comienzo</h3>
              <p>
                {format(new Date(retreat.date), "EEEE d MMMM HH:mm", {
                  locale: es,
                })}
              </p>
              <h3>Fecha de fin</h3>
              <p>
                {format(new Date(retreat.endDate), "EEEE d MMMM HH:mm", {
                  locale: es,
                })}
              </p>
            </Col>
            <Col xs={3}>
              <h3>Imagen del retiro</h3>
              <Image src={retreat.imageUrl} fluid />
            </Col>
          </Row>
        </Container>
      );
    }
    return <div></div>;
  }
}

const mapStateToProps = (state) => {
  return {
    retreats: getRetreats(state),
  };
};

export default connect(mapStateToProps)(RetreatDetail);
