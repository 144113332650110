import { handleActions } from "redux-actions";

export const uiReducer = handleActions(
  {
    SNACKBAR_ERROR: (state, action) => {
      return {
        ...state,
        successSnackbarOpen: false,
        errorSnackbarOpen: true,
        errorSnackbarMessage: action.payload.message,
        loadingSnackbarOpen: true,
      };
    },
    SNACKBAR_SUCCESS: (state, action) => {
      return {
        ...state,
        successSnackbarOpen: true,
        successSnackbarMessage: action.payload.message,
        loadingSnackbarOpen: false
      };
    },
    SNACKBAR_LOADING: (state, action) => {
      return {
        ...state,
        loadingSnackbarOpen: true
      }
    },
    SNACKBAR_CLEAR: (state, action) => {
      return {
        ...state,
        successSnackbarOpen: false,
        errorSnackbarOpen: false,
        infoSnackbarOpen: false,
        loadingSnackbarOpen: false
      };
    },
  },
  {}
);
