import { createAction } from "redux-actions";

export const socketActions = {
  LALA_DISCONNECT: createAction("LALA_DISCONNECT"),
  LALA_CONNECT: createAction("LALA_CONNECT", (host, port) => {
    return {
      host,
      port,
    };
  }),
};
