const action = "connect";
const dispatch = (socket, store, next, action) => () => {
  console.log("Socket is connected");
  store.dispatch({ type: "CONNECTED" });
};

export default {
  action,
  dispatch,
};
