import { handleActions } from "redux-actions";

export const classReducer = handleActions(
  {
    GET_CLASSES: (state, action) => {
      return {
        ...state,
        fetching: true,
      };
    },
    GET_CLASSES_FINISHED: (state, action) => {
      return {
        ...state,
        fetching: false,
        classes: action.payload,
      };
    },
    GET_CLASSES_ERROR: (state, action) => {
      return {
        ...state,
        fetching: false,
        error: action.error,
      };
    },
    CREATE_CLASS: (state, action) => {
      return {
        ...state,
        fetching: false,
      };
    },
    CREATE_CLASS_FINISHED: (state, action) => {
      return {
        ...state,
        fetching: false,
        classes: [...state.classes, action.payload.element],
      };
    },
    CREATE_CLASS_ERROR: (state, action) => {
      return {
        ...state,
        fetching: false,
        error: action.error,
      };
    },
    DELETE_CLASS: (state, action) => {
      return {
        ...state,
        fetching: true,
      };
    },
    DELETE_CLASS_FINISHED: (state, action) => {
      return {
        ...state,
        fetching: false,
        classes: [
          ...state.classes.filter(
            (element) => element._id !== action.payload.element._id
          ),
        ],
      };
    },
    DELETE_CLASS_ERROR: (state, action) => {
      return {
        ...state,
        fetching: false,
        error: action.error,
      };
    },
    EDIT_CLASS: (state, action) => {
      return {
        ...state,
        fetching: true,
      };
    },
    EDIT_CLASS_FINISHED: (state, action) => {
      return {
        ...state,
        classes: [
          ...state.classes.filter(
            (classElement) => classElement._id !== action.payload.element._id
          ),
          action.payload.element,
        ],
      };
    },
    CREATE_SUBCLASS: (state, action) => {
      return {
        ...state,
        fetching: true,
      };
    },
    CREATE_SUBCLASS_FINISHED: (state, action) => {
      console.log(state);
      console.log(action.payload.lalaClass);
      return {
        ...state,
        fetching: false,
        classes: [
          ...state.classes.filter(
            (element) => element._id !== action.payload.lalaClass._id
          ),
          action.payload.lalaClass,
        ],
      };
    },
    CREATE_SUBCLASS_ERROR: (state, action) => {
      return {
        ...state,
        fetching: false,
        error: action.error,
      };
    },
    DELETE_SUBCLASS: (state, action) => {
      return {
        ...state,
        fetching: true,
      };
    },
    DELETE_SUBCLASS_FINISHED: (state, action) => {
      return {
        ...state,
        fetching: false,
        classes: [
          ...state.classes.filter(
            (element) => element._id !== action.payload.lalaClass._id
          ),
          action.payload.lalaClass,
        ],
      };
    },
    DELETE_SUBCLASS_ERROR: (state, action) => {
      return {
        ...state,
        fetching: false,
        error: action.error,
      };
    },
    EDIT_SUBCLASS: (state, action) => {
      return {
        ...state,
        fetching: true,
      };
    },
    EDIT_SUBCLASS_FINISHED: (state, action) => {
      console.log(action.payload);
      return {
        ...state,
        fetching: false,
        classes: [
          ...state.classes.filter(
            (element) => element._id !== action.payload.lalaClass._id
          ),
          action.payload.lalaClass,
        ],
      };
    },
    EDIT_SUBCLASS_ERROR: (state, action) => {
      return {
        ...state,
        fetching: false,
        error: action.error,
      };
    },
  },
  {
    fetching: false,
    classes: [],
    error: null,
  }
);

export const getClasses = (state) => state.classState.classes;
export const getClassesFetching = (state) => state.classState.fetching;
export const getClassesError = (state) => state.classState.error;
