import { combineReducers } from "redux";
import { socketReducer } from "./socketReducer";
import { coursesReducer } from "./courseReducer";
import { classReducer } from "./classReducer";
import { authReducer } from "./authReducer";
import { firebaseReducer } from "react-redux-firebase";
import { uiReducer } from "./uiReducer";
import { usersReducer } from "./usersReducer";
import { retreatsReducer } from "./retreatReducer";

const rootReducer = combineReducers({
  authState: authReducer,
  classState: classReducer,
  courseState: coursesReducer,
  retreatState: retreatsReducer,
  socketState: socketReducer,
  firebaseState: firebaseReducer,
  usersState: usersReducer,
  uiState: uiReducer,
});

export default rootReducer;
