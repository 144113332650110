import React, { useEffect } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Route, Switch } from "react-router-dom";
import { getClassesAction } from "../../actions/classActions";
import ClassList from "./classList";
import classCreate from "./classCreate";
import SubClasses from "./subclasses";
import SubClassCreate from "./subclasses/subclassCreate";
import classDetails from "./classDetails";
import classEdit from "./classEdit";
import subclassEdit from "./subclasses/subclassEdit";
const Classes = (props) => {
  const { getClasses } = props;
  useEffect(() => {
    getClasses();
  }, [getClasses]);
  return (
    <Switch>
      <Route exact path="/classes" component={ClassList} />
      <Route exact path="/classes/create" component={classCreate} />
      <Route exact path="/classes/:id" component={classDetails} />
      <Route exact path="/classes/:id/edit" component={classEdit} />
      <Route exact path="/classes/:id/subclasses" component={SubClasses} />
      <Route
        exact
        path="/classes/:id/subclasses/create"
        component={SubClassCreate}
      />
      <Route
        exact
        path="/classes/:id/subclasses/:subclassId/edit"
        component={subclassEdit}
      />
    </Switch>
  );
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getClasses: () => getClassesAction(),
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(Classes);
