import React from "react";
import { Card, Button } from "react-bootstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { deleteClassAction } from "../../actions/classActions";
import { Link } from "react-router-dom";

const ClassItem = (props) => {
  const { deleteClass, lalaClass } = props;

  return (
    <Card style={{ border: "none" }} className="shadow h-100">
      <Card.Img
        variant="top"
        src={lalaClass.backgroundImageUrl}
        style={{ maxHeight: "150px", objectFit: "cover" }}
      />
      <Card.Body>
        <Card.Title>
          {lalaClass.title}
          {lalaClass.isTrainee ? <small> - Voluntariado</small> : ""}
        </Card.Title>
      </Card.Body>
      <Card.Footer className="text-center">
        <Link to={`/classes/${lalaClass._id}`}>
          <Button className="mainButton float-left">Ver Clase</Button>
        </Link>

        <Button
          variant="danger"
          style={{ border: "none" }}
          className="float-right"
          onClick={() => deleteClass(lalaClass)}
        >
          Borrar
        </Button>
      </Card.Footer>
    </Card>
  );
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      deleteClass: (lalaClass) => deleteClassAction(lalaClass),
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(ClassItem);
