import React, { Component } from "react";
import { connect } from "react-redux";
import { getRetreats } from "../../reducers/retreatReducer";
import { Container, Col, Row, Form, Button } from "react-bootstrap";
import { bindActionCreators } from "redux";
import { editRetreatAction } from "../../actions/retreatActions";
import {
  KeyboardDatePicker,
  KeyboardTimePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

class RetreatEdit extends Component {
  constructor(props) {
    super(props);
    this.image = React.createRef();
  }

  state = {
    retreat: this.props.retreats.find(
      (element) => element._id === this.props.match.params.id
    ),
    imageName: "",
  };

  handleChange = (e) => {
    e.persist();
    this.setState((prevState) => ({
      ...prevState,
      retreat: {
        ...prevState.retreat,
        [e.target.id]: !e.target.value.length ? undefined : e.target.value,
      },
    }));
  };

  handleDateChange = (date) => {
    this.setState((prevState) => ({
      ...prevState,
      retreat: {
        ...prevState.retreat,
        date: date,
      },
    }));
  };

  handleEndDateChange = (date) => {
    this.setState((prevState) => ({
      ...prevState,
      retreat: {
        ...prevState.retreat,
        endDate: date,
      },
    }));
  };

  handleTimeChange = (date) => {
    this.setState((prevState) => ({
      ...prevState,
      retreat: {
        ...prevState.retreat,
        date: date,
      },
    }));
  };

  handleImageChange = (e) => {
    e.persist();
    this.setState((prevState) => ({
      ...prevState,
      [e.target.id]: e.target.files[0].name,
    }));
  };

  handleOnSubmit = (e) => {
    e.preventDefault();
    const blob = this.image.current.files[0];
    this.props.editRetreat(
      this.props.match.params.id,
      this.state.retreat,
      blob
    );
  };

  render() {
    const { retreats, match } = this.props;
    const retreat = retreats.find((element) => element._id === match.params.id);
    if (retreat) {
      return (
        <Container className="mt-5">
          <Row>
            <Col>
              <h1 className="display-5">
                {retreat.title}
                <Button
                  onClick={() => this.props.history.goBack()}
                  className="borderedButtonRed ml-5 mb-1"
                >
                  Volver
                </Button>
              </h1>
            </Col>
          </Row>
          <br></br>
          <br></br>
          <Row>
            <Col>
              <Form onSubmit={this.handleOnSubmit}>
                <Form.Group>
                  <Form.Control
                    id="title"
                    type="text"
                    placeholder="Nombre del retiro"
                    onChange={this.handleChange}
                    defaultValue={retreat.title}
                  ></Form.Control>
                </Form.Group>
                <Form.Group>
                  <Form.Control
                    id="description"
                    as="textarea"
                    rows="4"
                    placeholder="Descripcion del retiro"
                    onChange={this.handleChange}
                    defaultValue={retreat.description}
                  ></Form.Control>
                </Form.Group>
                <Form.Group>
                  <Form.Control
                    id="link"
                    onChange={this.handleChange}
                    type="text"
                    placeholder="Video del retiro"
                  ></Form.Control>
                </Form.Group>
                <Form.Group>
                  <Form.Label>{this.state.imageName}</Form.Label>
                  <Form.File
                    ref={this.image}
                    id="imageName"
                    label="Imagen"
                    data-browse="Buscar"
                    onChange={this.handleImageChange}
                    custom
                  ></Form.File>
                </Form.Group>
                <Form.Group>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      disableToolbar
                      variant="inline"
                      format="dd/MM/yyyy"
                      margin="normal"
                      id="retreatDate"
                      label="Fecha del retiro"
                      value={this.state.retreat.date}
                      onChange={this.handleDateChange}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </Form.Group>
                <Form.Group>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      disableToolbar
                      variant="inline"
                      format="dd/MM/yyyy"
                      margin="normal"
                      id="retreatDate"
                      label="Fecha de fin del retiro"
                      value={this.state.retreat.endDate}
                      onChange={this.handleEndDateChange}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </Form.Group>
                <Button type="submit" className="mainButton">
                  Editar
                </Button>
              </Form>
            </Col>
          </Row>
        </Container>
      );
    }
    return <div></div>;
  }
}

const mapStateToProps = (state) => {
  return {
    retreats: getRetreats(state),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      editRetreat: (retreatId, retreat, blob) =>
        editRetreatAction(retreatId, retreat, blob),
    },
    dispatch
  );
export default connect(mapStateToProps, mapDispatchToProps)(RetreatEdit);
