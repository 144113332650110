import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { connect } from "react-redux";
import { getClasses } from "../../../reducers/classReducer";
import { Link } from "react-router-dom";
import SubclassList from "./subclassList";
import history from "../../../utils/history";
const SubClasses = (props) => {
  const { lalaClass } = props;

  if (!lalaClass) return <div></div>;
  return (
    <Container className="mt-5">
      <Row>
        <Col>
          <h1 className="display-5">
            Mis Subclases de {lalaClass.title}
            <Button
              onClick={() => history.goBack()}
              className="borderedButtonRed ml-5 mb-1"
            >
              Volver
            </Button>
            <Link to={`/classes/${lalaClass._id}/subclasses/create`}>
              <Button className="borderedButton ml-1 mb-1">
                Agregar Subclase
              </Button>
            </Link>
          </h1>
        </Col>
      </Row>
      <br></br>
      <SubclassList lalaClass={lalaClass} />
    </Container>
  );
};

const mapStateToProps = (state, ownParams) => {
  return {
    lalaClass: getClasses(state).find(
      (element) => element._id === ownParams.match.params.id
    ),
  };
};
export default connect(mapStateToProps)(SubClasses);
