import React, { Component } from "react";
import RetreatItem from "./retreatItem";
import { Row, Col, Button, Container } from "react-bootstrap";
import { connect } from "react-redux";

import {
  getRetreats,
  getRetreatsError,
  getRetreatsFetching,
} from "../../reducers/retreatReducer";
import { Link } from "react-router-dom";

class RetreatList extends Component {
  render() {
    const { retreats } = this.props;
    return (
      <Container className="mt-5">
        <Row>
          <Col>
            <h1 className="display-5">
              Mis Retiros
              <Link to="/retreats/create">
                <Button className="borderedButton ml-5 mb-1">
                  Agregar retiro
                </Button>
              </Link>
            </h1>
          </Col>
        </Row>
        <Row>
          {retreats &&
            retreats.map((retreat) => {
              return (
                <Col key={retreat._id} xl={3} className="pb-4">
                  <RetreatItem retreat={retreat} />
                </Col>
              );
            })}
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    retreats: getRetreats(state),
    error: getRetreatsError(state),
    fetching: getRetreatsFetching(state),
  };
};

export default connect(mapStateToProps)(RetreatList);
