import React, { Component } from "react";
import { Card, Button } from "react-bootstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { deleteCourseAction } from "../../actions/courseActions";
import { Link } from "react-router-dom";

class CourseItem extends Component {
  render() {
    const { course, deleteCourse } = this.props;
    return (
      <Card style={{ border: "none" }} className="shadow h-100">
        <Card.Img
          variant="top"
          src={course.imageUrl}
          style={{ maxHeight: "150px", objectFit: "cover" }}
        />
        <Card.Body>
          <Card.Title>{course.title}</Card.Title>
          {/* <Card.Text>{course.shortDescription}</Card.Text> */}
          <h5>${course.price}</h5>
        </Card.Body>
        <Card.Footer className="text-center">
          <Link to={`/courses/${course._id}`}>
            <Button className="mainButton float-left">Ver Curso</Button>
          </Link>

          <Button
            variant="danger"
            style={{ border: "none" }}
            className="float-right"
            onClick={() => deleteCourse(course)}
          >
            Borrar
          </Button>
        </Card.Footer>
      </Card>
    );
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      deleteCourse: (course) => deleteCourseAction(course),
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(CourseItem);
