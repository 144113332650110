import API from "../utils/api";
import { createAction } from "redux-actions";
import history from "../utils/history";
import { uiActions } from "./uiActions";

export const classActions = {
  GET_CLASSES: createAction("GET_CLASSES"),
  GET_CLASSES_FINISHED: createAction("GET_CLASSES_FINISHED", (classes) => {
    return [...classes];
  }),
  GET_CLASSES_FAILED: createAction("GET_CLASSES_FAILED", (error) => {
    return {
      error,
    };
  }),
  CREATE_CLASS: createAction("CREATE_CLASS"),
  CREATE_CLASS_FINISHED: createAction("CREATE_CLASS_FINISHED", (element) => {
    return {
      element,
    };
  }),
  CREATE_CLASS_FAILED: createAction("CREATE_CLASS_FAILED", (error) => {
    return {
      error,
    };
  }),
  DELETE_CLASS: createAction("DELETE_CLASS"),
  DELETE_CLASS_FINISHED: createAction("DELETE_CLASS_FINISHED", (element) => {
    return {
      element,
    };
  }),
  DELETE_CLASS_FAILED: createAction("DELETE_CLASS_FAILED", (error) => {
    return {
      error,
    };
  }),
  EDIT_CLASS: createAction("EDIT_CLASS"),
  EDIT_CLASS_FINISHED: createAction("EDIT_CLASS_FINISHED", (element) => {
    return {
      element,
    };
  }),
  EDIT_CLASS_FAILED: createAction("EDIT_CLASS_FAILED", (error) => {
    return {
      error,
    };
  }),
};

export const getClassesAction = () => async (
  dispatch,
  getState,
  getFirebase
) => {
  dispatch(classActions.GET_CLASSES());
  const firebase = getFirebase();
  const token = await firebase.auth().currentUser.getIdToken();
  API.get("/classes", { headers: { Authorization: `Bearer ${token}` } })
    .then((res) => {
      dispatch(classActions.GET_CLASSES_FINISHED(res.data));
    })
    .catch((error) => {
      dispatch(classActions.GET_CLASSES_FAILED(error));
    });
};

export const createClassAction = (lalaClass, blob, backgroundBlob) => async (
  dispatch,
  getState,
  getFirebase
) => {
  dispatch(classActions.CREATE_CLASS());
  dispatch(uiActions.SNACKBAR_LOADING());
  const firebase = getFirebase();
  const token = await firebase.auth().currentUser.getIdToken();
  const data = new FormData();
  data.append("file", backgroundBlob);
  API.post("/classes/upload", data, {
    headers: { Authorization: `Bearer ${token}` },
  })
    .then((res) => {
      lalaClass.backgroundImageUrl = res.data;
      const data2 = new FormData();
      data2.append("file", blob);
      API.post("/classes/upload", data2, {
        headers: { Authorization: `Bearer ${token}` },
      })
        .then((res) => {
          lalaClass.imageUrl = res.data;
          API.post("/classes", lalaClass, {
            headers: { Authorization: `Bearer ${token}` },
          })
            .then((res) => {
              dispatch(classActions.CREATE_CLASS_FINISHED(res.data));
              dispatch(uiActions.SNACKBAR_SUCCESS("Clase creada"));
              history.goBack();
            })
            .catch((error) => {
              dispatch(classActions.CREATE_CLASS_FAILED(error));
              dispatch(uiActions.SNACKBAR_ERROR("Error al crear la clase"))
            });
        })
        .catch((error) => {
          dispatch(classActions.CREATE_CLASS_FAILED(error));
          dispatch(uiActions.SNACKBAR_ERROR("Error al crear la clase"))
        });
    })
    .catch((error) => {
      dispatch(classActions.CREATE_CLASS_FAILED(error));
      dispatch(uiActions.SNACKBAR_ERROR("Error al crear la clase"))
    });
};

export const deleteClassAction = (lalaClass) => async (
  dispatch,
  getState,
  getFirebase
) => {
  dispatch(classActions.DELETE_CLASS());
  dispatch(uiActions.SNACKBAR_LOADING());
  const firebase = getFirebase();
  const token = await firebase.auth().currentUser.getIdToken();
  API.delete(`/classes/${lalaClass._id}`, {
    headers: { Authorization: `Bearer ${token}` },
  })
    .then((res) => {
      dispatch(classActions.DELETE_CLASS_FINISHED(res.data));
      dispatch(uiActions.SNACKBAR_SUCCESS("Clase borrada"));
    })
    .catch((error) => {
      dispatch(classActions.DELETE_CLASS_FAILED(error));
      dispatch(uiActions.SNACKBAR_ERROR("Error al borrar la clase"))
    });
};

export const editClassAction = (
  lalaClassId,
  lalaClass,
  blob,
  backgroundBlob
) => async (dispatch, getState, getFirebase) => {
  dispatch(classActions.EDIT_CLASS());
  dispatch(uiActions.SNACKBAR_LOADING());
  const firebase = getFirebase();
  const token = await firebase.auth().currentUser.getIdToken();
  if (backgroundBlob && blob) {
    const data = new FormData();
    data.append("file", blob);
    API.post("/classes/upload", data, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => {
        lalaClass.imageUrl = res.data;
        const data2 = new FormData();
        data2.append("file", backgroundBlob);
        API.post("/classes/upload", data2, {
          headers: { Authorization: `Bearer ${token}` },
        })
          .then((res) => {
            lalaClass.backgroundImageUrl = res.data;
            API.put(`/classes/${lalaClassId}`, lalaClass, {
              headers: { Authorization: `Bearer ${token}` },
            })
              .then((res) => {
                dispatch(classActions.EDIT_CLASS_FINISHED(res.data));
                dispatch(uiActions.SNACKBAR_SUCCESS("Clase actualizada"));
                history.goBack();
              })
              .catch((error) => {
                dispatch(classActions.EDIT_CLASS_FAILED(error));
                dispatch(uiActions.SNACKBAR_ERROR("No se pudo actualizar"));
              });
          })
          .catch((error) => {
            dispatch(classActions.EDIT_CLASS_FAILED(error));
            dispatch(uiActions.SNACKBAR_ERROR("No se pudo actualizar"));
          });
      })
      .catch((error) => {
        dispatch(classActions.EDIT_CLASS_FAILED(error));
        dispatch(uiActions.SNACKBAR_ERROR("No se pudo actualizar"));
      });
  } else if (blob) {
    const data = new FormData();
    data.append("file", blob);
    API.post("/classes/upload", data, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => {
        lalaClass.imageUrl = res.data;

        API.put(`/classes/${lalaClassId}`, lalaClass, {
          headers: { Authorization: `Bearer ${token}` },
        })
          .then((res) => {
            dispatch(classActions.EDIT_CLASS_FINISHED(res.data));
            dispatch(uiActions.SNACKBAR_SUCCESS("Clase actualizada"));
            history.goBack();
          })
          .catch((error) => {
            dispatch(classActions.EDIT_CLASS_FAILED(error));
            dispatch(uiActions.SNACKBAR_ERROR("No se pudo actualizar"));
          });
      })
      .catch((error) => {
        dispatch(classActions.EDIT_CLASS_FAILED(error));
        dispatch(uiActions.SNACKBAR_ERROR("No se pudo actualizar"));
      });
  } else if (backgroundBlob) {
    const data = new FormData();
    data.append("file", backgroundBlob);
    API.post("/classes/upload", data, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => {
        lalaClass.backgroundImageUrl = res.data;

        API.put(`/classes/${lalaClassId}`, lalaClass, {
          headers: { Authorization: `Bearer ${token}` },
        })
          .then((res) => {
            dispatch(classActions.EDIT_CLASS_FINISHED(res.data));
            dispatch(uiActions.SNACKBAR_SUCCESS("Clase actualizada"));
            history.goBack();
          })
          .catch((error) => {
            dispatch(classActions.EDIT_CLASS_FAILED(error));
            dispatch(uiActions.SNACKBAR_ERROR("No se pudo actualizar"));
          });
      })
      .catch((error) => {
        dispatch(classActions.EDIT_CLASS_FAILED(error));
        dispatch(uiActions.SNACKBAR_ERROR("No se pudo actualizar"));
      });
  } else {
    API.put(`/classes/${lalaClassId}`, lalaClass, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => {
        dispatch(classActions.EDIT_CLASS_FINISHED(res.data));
        dispatch(uiActions.SNACKBAR_SUCCESS("Clase actualizada"));
        history.goBack();
      })
      .catch((error) => {
        dispatch(classActions.EDIT_CLASS_FAILED(error));
        dispatch(uiActions.SNACKBAR_ERROR("No se pudo actualizar"));
      });
  }
};
